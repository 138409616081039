import { Constants } from "@eagerdog/constants";
import { IEventRegisterRow } from "../../../services/helper.service.js";

export namespace UKCPrecisionCoursing {
  export const form:IEventRegisterRow[] = [{
    allowDuplicates: false,
    inputs: [{
      type: "dropdown", flex: 2, label: Constants.dog_class_element.precision_coursing, options: [
        { value: "None", id: "None" },
        { value: Constants.dog_class_element_level.novice, id: Constants.dog_class_element_level.novice },
        { value: Constants.dog_class_element_level.advanced, id: Constants.dog_class_element_level.advanced },
        { value: Constants.dog_class_element_level.superior, id: Constants.dog_class_element_level.superior },
        { value: Constants.dog_class_element_level.master, id: Constants.dog_class_element_level.master },
        { value: Constants.dog_class_element_level.elite, id: Constants.dog_class_element_level.elite },
        { value: Constants.dog_class_element_level.dash, id: Constants.dog_class_element_level.dash },
        { value: Constants.dog_class_element_level.steeplechase, id: Constants.dog_class_element_level.steeplechase },
      ]
    }]
   }, {
    allowDuplicates: false,
    inputs: [{
      type: "checkbox", multiSelect: true, label: Constants.dog_class_element.conformation_non_licensed, options: [
        { value: Constants.dog_class_element_level.fun_run, id: Constants.dog_class_element_level.fun_run },
        { value: Constants.dog_class_element_level.puppy_dash, id: Constants.dog_class_element_level.puppy_dash }
      ]
    }]
   }];
}