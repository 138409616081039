import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Constants } from "@eagerdog/constants";
import { AxiosError } from 'axios';
import { Helmet } from "react-helmet";
import moment from "moment";

import { IScore, IUser, IEventSummary, QueryAttributeDto, QueryAttributeGroupDto, IShowElement, IShow, QueryDto, IShowRegistrationSummary, IEventMetrics, IEventTransactionSummary /*IDiscount*/ } from "@eagerdog/interfaces";

import { helperService } from "../../services/helper.service";
import { apiService } from "src/services/api.service";

import { useDebounce } from "src/hooks/useDebounce";

import Table, { IHeader, IRowAction } from "src/components/Table/Table";
import TabContent, { ITab, useTabContent } from "src/components/TabContent/TabContent";
import { Modal, useModal } from "src/components/Modal/Modal";
import { toast } from "src/components/Toast/ToastManager";
import { download } from "src/components/Download/DownloadManager";
import DiscountForm from "src/components/DiscountForm/DiscountForm";
import EditRegistration from "src/components/EditRegistration/EditRegistration";
import Button from "src/components/Button/Button";
import Dropdown, { IOption } from "src/components/Dropdown/Dropdown";
import Input from "src/components/Input/Input";
import EnterResults from "src/components/EnterResults/EnterResults";
import MoveUp from "src/components/MoveUp/MoveUp";
import RibbonSkip from "src/components/RibbonSkip/RibbonSkip";
import Refund from "src/components/Refund/Refund";
import Confirm from "src/components/Confirm/Confirm";
import SendMessage from "src/components/SendMessage/SendMessage";
import ChangeElements from "src/components/ChangeElements/ChangeElements";

import "../../styles/ManagePage.scss";
import "./EventManage.scss";

interface IDiscountTableContent {
  discount_code: string,
  discount_value: string,
  discount_type: string,
  actions: IRowAction[]
}

interface IRegistrantTable {
  dogName: string,
  showName: string,
  regNum: string,
  actions: IRowAction[]
}

interface IResultsTable {
  dogName: string,
  result: JSX.Element,
  breed: string,
  level: string,
  status: JSX.Element,
  actions: IRowAction[]
}

interface ITransTable {
  name: string,
  processed_amount: string,
  club_amount: string,
  refunded_amount: string,
  //currency: JSX.Element,
  date: string,
  actions: IRowAction[]
}

interface IShowOption extends IOption {
  show_elements?: IShowElement[]
}

const EventManage: React.FC = () => {
  const { isShown, toggle } = useModal();
  const navigate = useNavigate();
  let { eid } = useParams();

  const user:IUser = helperService.getUser();

  const [loaded, setLoaded] = useState<boolean>(false);
  const [event, setEvent] = useState<IEventSummary>();
  const [modalContent, setModalContent] = useState<string>("");

  const [eventRegistration, setEventRegistration] = useState<IShowRegistrationSummary>();
  const [changeRegistration, setChangeRegistration] = useState<IScore>();

  const [result, setResult] = useState<IScore>();
  const [showResultDownloads, setShowResultDownloads] = useState<boolean>(false);

  const [show, setShow] = useState<IShowOption>({ id: "None", value: "None" });
  const [showElement, setShowElement] = useState<IOption>({ id: "None", value: "None" });
  const [showLevel, setShowLevel] = useState<IOption>({ id: "None", value: "None" });
  const [showSection, setShowSection] = useState<IOption>({id: "None", value: "None" });

  // Search Setup
  const [searchQuery, setSearchQuery] = useState<string>("");
  const debouncedValue = useDebounce<string>(searchQuery, 500);

  const [shows, setShows] = useState<IShowOption[]>([]);
  const [showElements, setShowElements] = useState<IOption[]>([]);

  const [isEmailing, setIsEmailing] = useState<boolean>(false);
  const [resultEmail, setResultEmail] = useState<IScore>();

  const [removeDog, setRemoveDog] = useState<IShowRegistrationSummary>();
  const [isRemovingDog, setIsRemovingDog] = useState<boolean>(false);

  const { activeTab, setTab } = useTabContent();

  const [levels, setLevels] = useState<IOption[]>([
    { value: "None", id: "None" },
    { value: Constants.dog_class_element_level.novice, id: Constants.dog_class_element_level.novice },
    { value: Constants.dog_class_element_level.advanced, id: Constants.dog_class_element_level.advanced },
    { value: Constants.dog_class_element_level.superior, id: Constants.dog_class_element_level.superior },
    { value: Constants.dog_class_element_level.master, id: Constants.dog_class_element_level.master },
    { value: Constants.dog_class_element_level.elite, id: Constants.dog_class_element_level.elite }
  ]);

  const sections:IOption[] = [
   { id: Constants.dog_class_section.nosework_a, value: Constants.dog_class_section.nosework_a },
   { id: Constants.dog_class_section.nosework_b, value: Constants.dog_class_section.nosework_b }
  ];

  const [metrics, setMetrics] = useState<IEventMetrics>({
    dogs: 0,
    entries: 0,
    revenue: 0
  });

  const [formatter, setFormatter] = useState<any>(new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }));

  const tabs:ITab[] = [
    { name: "Registrants", actions: [] },
    { name: "Discounts", actions: [] },
    { name: "Results", actions: [] },
    { name: "Transactions", actions: [] }
  ];

  const discountTableHeaders:IHeader[] = [
    { label: 'Code', id: 'discount_code' },
    { label: 'Discount', id: 'discount_value' },
    { label: 'Type', id: 'discount_type' },
    { label: 'Actions', id: 'actions' }
  ];

  const [registrantHeaders, setRegistrantHeaders] = useState<IHeader[]> ([
    { label: "Call Name", id: "dogName" },
    { label: "Registered Name", id: "regName" },
    { label: "Armband #", id: "armNum"},
    { label: "Breed", id: "breed" },
    { label: "Show", id: "show" },
    { label: "Show Date", id: "showDate" },
    { label: "Registered At", id: "createdAt" },
    { label: "Actions", id: "actions"}
  ]);

  const resultsHeaders:IHeader[] = [
    { label: "Call Name", id: "dogName" },
    { label: "Result", id: "result" },
    { label: "Breed", id: "breed" },
    { label: "Element / Level", id: "level" },
    { label: "Status", id: "status" },
    { label: "Actions", id: "actions" }
  ];

  const transHeaders:IHeader[] = [
    { label: "Name", id: "name" },
    { label: "Processed Amount", id: "processed_amount" },
    { label: "Club Amount", id: "club_amount" },
    { label: "Refunded", id: "refunded" },
    { label: "Date", id: "date" },
    { label: "Actions", id: "actions" }
  ];

  const [results, setResults] = useState<IResultsTable[]>([]);
  const [resultSkip, setResultSkip] = useState<number>(0);

  const [registrants, setRegistrants] = useState<IRegistrantTable[]>([]);
  const [regSkip, setRegSkip] = useState<number>(0);

  const [discountData, setDiscountData] = useState<IDiscountTableContent[]>([]);
  const [discSkip, setDiscSkip] = useState<number>(0);

  const [transData, setTransData] = useState<ITransTable[]>([]);
  const [transSkip, setTransSkip] = useState<number>(0);

  const [refundInfo, setRefundInfo] = useState<IEventTransactionSummary>();

  const pModalContent = helperService.usePrevious(modalContent);

  useEffect(() => {
    if (pModalContent !== modalContent) {
      if (modalContent !== "") {
        toggle();
      }
    } else {
      if (!isShown) {
        setModalContent("");
      }
    }
  }, [isShown, modalContent, pModalContent, setModalContent, toggle]);

  const getLevelName = (result: any, _showType: string) => {

    if (_showType === Constants.show_type.nosework) {
      return result.dog_class.show_element +" "+ result.dog_class.level +" ("+ result.dog_class.section +")";
    } else if (result.dog_class) {
      return result.dog_class.show_element +" "+ result.dog_class.level;
    }

    return "N/A";
  };

  const getEventDiscounts = useCallback((id: string, query: QueryDto, skip?: number) => {
    apiService.getEventDiscounts(id, query).then((discounts) => {
      let _data:IDiscountTableContent[] = [];

      for (let d in discounts) {
        _data.push({
          discount_code: discounts[d].discount_code,
          discount_value: discounts[d].discount_value.toString(),
          discount_type: discounts[d].discount_type,
          actions: [
            {
              icon: "delete",
              onClick: () => {
                if (event) {
                  apiService.deleteEventDiscount(event._id, discounts[d]._id).then((response) => {
                    getEventDiscounts(event._id, {} );
                  });
                }
              }
            }
          ]
        });
      }

      setDiscountData(_data);
    }).catch((e: AxiosError) => {
      toast.show({
        title: "Get Discounts",
        content: "Unable to get discounts",
        duration: 10000,
        errorDetails: e,
        type: "fail"
      });
    });
  }, [event]);

  const getEventScores = useCallback((id: string, query: QueryDto, _showId: string, _showElement: string, _showLevel:string, _showSection: string, skip?: number, _showType?: string) => {
    let _query:any = {
      query: {
        "$and": []
      },
      sort: [{
        attribute_name: "arm_number",
        sort: "asc"
      }]
    };

    if (_showSection !== "None") {
        _query.query["$and"].push({
          attribute_name: "dog_class.section",
          attribute_value: {
              operator: "$eq",
              value: _showSection
          }
        });
    }

    if (_showLevel !== "None") {
        _query.query["$and"].push({
          attribute_name: "dog_class.level",
          attribute_value: {
              operator: "$eq",
              value: _showLevel
          }
        });
    }


    if (_showElement !== "None") {
      _query.query["$and"].push({
        attribute_name: "dog_class.show_element",
        attribute_value: {
            operator: "$eq",
            value: _showElement
        }
      });
    }


    _query.query["$and"].push({
      attribute_name: "show_id",
      attribute_value: {
          operator: "$eq",
          value: _showId.split(":")[0]
      }
    });

    if (query.query) {
      _query.query["$and"].push({
        "$or" : JSON.parse(JSON.stringify(query.query["$or"]))
      });
    }

    apiService.getEventScores(id, _query).then((response) => {
      let results:IResultsTable[] = [];

      let resultTypeLabels:any = {
        not_qualified: "NQ",
        qualified: "Qualified",
        disqualified: "Disqualified",
        excused: "Excused",
        absent: "Absent",
        passed: "Passed",
        failed: "Failed"
      }

      for (let r in response) {
        let result = response[r];


      let hasResults:string[] = [
        Constants.show_type.nosework,
        Constants.show_type.fast_cat,
        Constants.show_type.fetch
      ];

      const getResultType = (_result: any) => {
        if (result.nosework) {
          return result.nosework.result_type;
        } else if (result.fetch) {
          return result.fetch.passed === true ? "passed" : "failed";
        }

        return "";
      }

      results.push({
        dogName: result.call_name + " " + (result.arm_number !== undefined ? "(#" + result.arm_number + ")" : "") || "",
        result: <span className={"status " + getResultType(result)}>{resultTypeLabels[getResultType(result)] || "N/A"}</span>,
        breed: result.breed || "",
        level: getLevelName(result, _showType ? _showType : ""),
        status: <span className={"status " + result.status}>{result.status}</span>,
        actions: _showType && hasResults.includes(_showType) ? [
          {
            icon: "pencil",
            onClick: () => {
              setResult(result); setModalContent("enterResultsModal");
            }
          }, {
            icon: "more",
            options: [
              {
                label: "Move Up",
                onClick: () => {
                  setChangeRegistration(result);
                  setModalContent("moveUpModal");
                }
              }
            ]
          }
        ] : [
          {
            icon: "more",
            options: [
              {
                label: "Move Up",
                onClick: () => {
                  setChangeRegistration(result);
                  setModalContent("moveUpModal");
                }
              }
            ]
          }
        ]
      });

      if (result.status === Constants.score_status.complete) {
        results[results.length - 1]?.actions[1]?.options?.push({
          label: "Email Results",
          onClick: () => {
            setResultEmail(result);
            setModalContent("emailSingleModal");
          }
        });
      }
    }

      setResults(results);
    }).catch((e: AxiosError) => {
      toast.show({
        title: "Get Results",
        content: "Unable to get results",
        duration: 10000,
        errorDetails: e,
        type: "fail"
      });
    });
  }, []);

  const bulkEmailResults = () => {
    if (event) {
      setIsEmailing(true);

      apiService.requestResultsEmailForEvent(event._id, Constants.show_type.nosework).then((response) => {
        toast.show({
          title: "Bulk Email Results",
          content: "You've successfully bulk emailed all event results",
          duration: 10000,
          type: "success"
        });  
      }).catch((e: AxiosError) => {
        toast.show({
          title: "Bulk Email Results",
          content: "Unable to bulk email results",
          duration: 10000,
          errorDetails: e,
          type: "fail"
        });
      }).finally(() => {
        toggle();
        setModalContent("");
        setIsEmailing(false);
      });
    }
  }

  const emailResults = () => {
    if (event && resultEmail) {
      setIsEmailing(true);

      apiService.requestResultsEmailForShowRegistration(event._id, resultEmail._id).then((response) => {
        toast.show({
          title: "Email Results",
          content: "You've successfully emailed results to " + resultEmail.owner_name,
          duration: 10000,
          type: "success"
        });  
      }).catch((e: AxiosError) => {
        toast.show({
          title: "Email Results",
          content: "Unable to email results",
          duration: 10000,
          errorDetails: e,
          type: "fail"
        });
      }).finally(() => {
        toggle();
        setModalContent("");
        setIsEmailing(false);
      });
    }
  }

  //Send Message to all registrants
  const sendMessage = (message:string) => {
    if (event) {
      setIsEmailing(true);

      apiService.requestSendMessageEmailForEvent(event._id, message).then((response) => {
        toast.show({
          title: "Email Results",
          content: "Your message has been sent to all current registrants.",
          duration: 10000,
          type: "success"
        });  
      }).catch((e: AxiosError) => {
        toast.show({
          title: "Email Results",
          content: "Unable to email results",
          duration: 10000,
          errorDetails: e,
          type: "fail"
        });
      }).finally(() => {
        toggle();
        setModalContent("");
        setIsEmailing(false);
      });
    }
  }
  

  const getEventRegistrants = useCallback((id: string, query: QueryDto, skip?: number, sanctioningClub?: string) => {
    apiService.getEventShowRegistrations(id, query).then((regResponse) => {
      let _registrants:any[] = [];

      for (let r in regResponse) {
        let _dogName:string = regResponse[r].dog.call_name+' ('+regResponse[r].dog.sex.substring(0,1).toUpperCase()+')';

        /*
        if ((event && event.sanctioning_club === Constants.sanctioning_club.AKC) || (sanctioningClub === Constants.sanctioning_club.AKC)) {
          _dogName = regResponse[r].dog.registered_name+' ('+regResponse[r].dog.sex.substring(0,1).toUpperCase()+')';
        }
        */

        _registrants.push({
          dogName: _dogName,
          regName: regResponse[r].dog.registered_name,
          armNum: regResponse[r]?.arm_number,
          breed: regResponse[r].dog.breed,
          show: regResponse[r].show_type + " " + regResponse[r].show_name,
          showDate: moment(regResponse[r].show_date).format("MM/DD/YY"),
          createdAt: moment(regResponse[r].created_at).format("MM/DD/YY h:mm:ss A"),
          actions: [
            {
              icon: "download",
              onClick: () => {
                download.pushDownload({
                  type: "registration",
                  registrantId: event ? event._id : id || "",
                  dogId:  regResponse[r].dog.dog_id
                });
              }
            }, {
              icon: "more",
              options: [
                {
                  label: "Edit Registration",
                  onClick: () => {
                    setEventRegistration(regResponse[r]);
                    setModalContent("editRegModal");
                  }
                }, {
                  label: "Change Elements",
                  onClick: () => {
                    setEventRegistration(regResponse[r]);
                    setModalContent("changeModal");
                  }
                }, {
                  label: "Remove Dog",
                  onClick: () => {
                    setRemoveDog(regResponse[r]);
                  }
                }
              ]
            }
          ]
        });
      }

      setRegistrants(_registrants);
    }).catch((e: AxiosError) => {
      toast.show({
        title: "Get Registrants",
        content: "Unable to get registrants",
        duration: 10000,
        errorDetails: e,
        type: "fail"
      });
    });
  }, [event]);

  const getEventTransactions = useCallback((id: string, query: QueryDto, skip?: number) => {
    let _query:any = {
      query: {
        "$and": []
      }
    };

    _query.query["$and"].push({
      attribute_name: "status",
      attribute_value: {
          operator: "$eq",
          value: "success"
      }
    });

    if (query.query) {
      _query.query["$and"].push({
        "$or" : JSON.parse(JSON.stringify(query.query["$or"]))
      });
    }


    apiService.getEventTransactions(id, _query).then((transResponse) => {
      let _transactions:ITransTable[] = [];

      for (let r in transResponse) {
        let _t = transResponse[r];

        let formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: _t.amounts.currency.toUpperCase(),
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });

        let record:ITransTable = {
          name: _t.user.first_name + " " + _t.user.last_name,
          processed_amount: formatter.format((_t.amounts.processed_total_amount ? _t.amounts.processed_total_amount  / 100 : 0) as number),
          club_amount: formatter.format((_t.amounts.processed_amount ? _t.amounts.processed_amount  / 100 : 0) as number),
          refunded_amount: formatter.format((_t.amounts.refunded_amount ? _t.amounts.refunded_amount  / 100 : 0) as number),
          //currency: <span className={"status " + getTransactionStatus(_t)}>{getTransactionStatus(_t)}</span>,
          date: moment(_t.created_at).format("DD/MM/YYYY @ h:mmA"),
          actions: []
        };

        if (getTransactionStatus(_t) !== "refunded") {
          record.actions.push({
            icon: "refund",
            onClick: () => {
              if (event && _t.amounts.calculated_base_amount !== undefined) {
                setRefundInfo(_t);
              }
            }
          });
        }

        _transactions.push(record);
      }

      setTransData(_transactions);
    }).catch((e: AxiosError) => {
      toast.show({
        title: "Get Transactions",
        content: "Unable to get transactions",
        duration: 10000,
        errorDetails: e,
        type: "fail"
      });
    });
  }, [event]);

  const getTransactionStatus = (transaction: IEventTransactionSummary) => {
    if (transaction.amounts !== undefined && transaction.amounts.refunded_amount !== undefined && transaction.amounts.refunded_amount !== 0) {
      if (transaction.amounts.refunded_amount === transaction.amounts.calculated_base_amount) {
        return "refunded";
      } else {
        return "partially refunded";
      }
    }

    return "complete";
  }

  const generateSearchQuery = useCallback((term: string) => {
    const createRegexCondition = (attributeName: string): QueryAttributeDto => ({
        attribute_name: attributeName,
        attribute_value: {
            operator: "$regex",
            value: term
        }
    });

    let conditions: QueryAttributeDto[] = [];

    switch(activeTab) {
      case 0:
        conditions = [
          createRegexCondition("dog.call_name"),
          createRegexCondition("dog.registered_name"),
          createRegexCondition("dog.breed"),
          createRegexCondition("show_name"),
          createRegexCondition("show_type"),
          createRegexCondition("owner.display_name"),
          createRegexCondition("owner.email"),
          createRegexCondition("arm_number")
        ];
      break;
      case 1:
        //Discounts, no search bar.
      break;
      case 2:
        conditions = [
          createRegexCondition("breed"),
          createRegexCondition("call_name"),
          createRegexCondition("owner_name"),
          createRegexCondition("arm_number")
        ];
      break;
    }

    const queryGroup: QueryAttributeGroupDto = {
        "$or": conditions
    };

    return queryGroup;
  }, [activeTab]);

  useEffect(() => {
    if (!helperService.isLoggedIn()) {
      toast.show({
        title: "Event Manage",
        content: "Please login to access account information",
        duration: 10000,
        type: "fail"
      });

      navigate("/login?r=/e/" + eid);
    }

    if (!loaded && eid) {
      apiService.getEventSummaryForHandle(eid).then((response) => {
        if (response.club.created_by !== user._id) {
          toast.show({
            title: "Event Manage",
            content: "Only club owners can manage an event",
            duration: 10000,
            type: "fail"
          });

          navigate("/e/" + eid);
        }

        setFormatter(new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: response.currency || "USD",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }));

        apiService.getEventMetrics(response._id).then((responseMetrics) => {
          setMetrics(responseMetrics);
        }).catch((e: AxiosError) => {
          toast.show({
            title: "Get Metrics",
            content: "Unable to get event metrics",
            duration: 10000,
            errorDetails: e,
            type: "fail"
          });
        })

        setEvent(response);

        if (response.sanctioning_club === Constants.sanctioning_club.AKC) {
          setRegistrantHeaders([
            { label: "Call Name", id: "dogName" },
            { label: "Registered Name", id: "regName" },
            { label: "Armband #", id: "armNum"},
            { label: "Breed", id: "breed" },
            { label: "Show", id: "show" },
            { label: "Show Date", id: "showDate" },
            { label: "Date/Time Registered", id: "createdAt" },
            { label: "Actions", id: "actions"}
          ]);
        }

        apiService.getClubEventShows(response.club._id, response._id).then((eventShows) => {
          let showOptions = eventShows.map((show: IShow) => {
            return {
              id: show._id+":"+show.show_type,
              value: show.show_type + " " + show.show_name + " (" + moment(show.show_date).format("MMM Do") + ")",
              show_elements: show.show_elements,
              disabled: false
            };
          });

          setShows(showOptions);
          const queryParameters = new URLSearchParams(window.location.search);
          const paramShow = queryParameters.get("show");

          if (paramShow) {
            setTab(2);

            let findShow:IShowOption = showOptions.filter((opt: IShowOption) => {
              if (opt.id === paramShow) {
                return opt;
              } else {
                return undefined;
              }
            })[0];

            if (findShow !== undefined) {
              setShow(findShow);
            }
          } else {
            getEventRegistrants(response._id, {
              sort: [{
                attribute_name: "show_type",
                sort: "asc"
              }, {
                attribute_name: "created_at",
                sort: "asc"
              }],
            }, 0, response.sanctioning_club);
          }
        });
      });

      setLoaded(true);
    }
  }, [loaded, eid, navigate, user._id, setTab, getEventRegistrants]);

  const pDebouncedValue = helperService.usePrevious(debouncedValue);
  const pActiveTab = helperService.usePrevious(activeTab);

  useEffect(() => {
    if (activeTab !== pActiveTab && event) {
      switch(activeTab) {
        case 0:
          getEventRegistrants(
            event._id,
            {
              sort: [{
                attribute_name: "show_type",
                sort: "asc"
              }, {
                attribute_name: "created_at",
                sort: "asc"
              }]
            },
            regSkip
          );
        break;
        case 1:
          getEventDiscounts(event._id, {}, discSkip);
        break;
        case 2:
          if (show.id !== "None") {
            getEventScores(
              event._id,
              {
                query: generateSearchQuery(searchQuery)
              },
              show.id.split(":")[0],
              showElement.id,
              showLevel.id,
              showSection.id,
              resultSkip,
              show.id.split(":")[1]
            );
          }
        break;
        case 3:
          getEventTransactions(event._id, {}, transSkip);
        break;
      }
    }

    if (debouncedValue !== pDebouncedValue && event) {
      switch (activeTab) {
        case 0:
          getEventRegistrants(
            event._id,
            {
              sort: [{
                attribute_name: "show_type",
                sort: "asc"
              }, {
                attribute_name: "created_at",
                sort: "asc"
              }],
              query: generateSearchQuery(searchQuery)
            },
            regSkip
          );
        break;
        case 1:
          //Discounts, no search bar.
        break;
        case 2:
          if (show.id !== "None") {
            getEventScores(
              event._id,
              {
                query: generateSearchQuery(searchQuery)
              },
              show.id.split(":")[0],
              showElement.id,
              showLevel.id,
              showSection.id,
              resultSkip,
              show.id.split(":")[1]
            );
          }
        break;
      }
    }
  }, [show, showElement, showLevel, showSection, debouncedValue, pDebouncedValue, activeTab, event, discSkip, generateSearchQuery, getEventDiscounts, getEventRegistrants, getEventScores, getEventTransactions, transSkip, pActiveTab, regSkip, resultSkip, searchQuery]);

  useEffect(() => {
    let _showType:string = show.id.split(":")[1];

    if (show.show_elements !== undefined) {
      let elementOptions:any[] = show.show_elements.map((se: IShowElement) => {
        if (_showType !== se.show_element || (event && event.sanctioning_club !== Constants.sanctioning_club.AKC && _showType === Constants.show_type.conformation)) {
          return {
            id: se.show_element,
            value: se.show_element
          };
        } else {
          return {
            id: "None",
            value: "None"
          };
        }
      });

      elementOptions = elementOptions.filter((opt: IOption) => opt.id !== "None" && opt.id !== Constants.dog_class_element.conformation_non_licensed);

      setShowElements(elementOptions);
    }

    if (_showType === Constants.show_type.rally_obedience) {
      if (event && event.sanctioning_club === Constants.sanctioning_club.AKC) {
        setLevels([
          { value: Constants.dog_class_element_level.novice_a, id: Constants.dog_class_element_level.novice_a },
          { value: Constants.dog_class_element_level.novice_b, id: Constants.dog_class_element_level.novice_b },
          { value: Constants.dog_class_element_level.intermediate, id: Constants.dog_class_element_level.intermediate },
          { value: Constants.dog_class_element_level.advanced_a, id: Constants.dog_class_element_level.advanced_a },
          { value: Constants.dog_class_element_level.advanced_b, id: Constants.dog_class_element_level.advanced_b },
          { value: Constants.dog_class_element_level.excellent_a, id: Constants.dog_class_element_level.excellent_a },
          { value: Constants.dog_class_element_level.excellent_b, id: Constants.dog_class_element_level.excellent_b },
          { value: Constants.dog_class_element_level.master, id: Constants.dog_class_element_level.master },
          { value: Constants.dog_class_element_level.choice, id: Constants.dog_class_element_level.choice },
          { value: Constants.dog_class_element_level.pairs, id: Constants.dog_class_element_level.pairs },
          { value: Constants.dog_class_element_level.team, id: Constants.dog_class_element_level.team },
          { value: Constants.dog_class_element_level.rae, id: Constants.dog_class_element_level.rae },
          { value: Constants.dog_class_element_level.raem, id: Constants.dog_class_element_level.raem }
        ]);
      } else {
        setLevels([
          { value: Constants.dog_class_element_level.ro1_a, id: Constants.dog_class_element_level.ro1_a },
          { value: Constants.dog_class_element_level.ro1_b, id: Constants.dog_class_element_level.ro1_b },
          { value: Constants.dog_class_element_level.ro1_c, id: Constants.dog_class_element_level.ro1_c },
          { value: Constants.dog_class_element_level.ro2_a, id: Constants.dog_class_element_level.ro2_a },
          { value: Constants.dog_class_element_level.ro2_b, id: Constants.dog_class_element_level.ro2_b },
          { value: Constants.dog_class_element_level.ro3_a, id: Constants.dog_class_element_level.ro3_a },
          { value: Constants.dog_class_element_level.ro3_b, id: Constants.dog_class_element_level.ro3_b },
          { value: Constants.dog_class_element_level.rom_a, id: Constants.dog_class_element_level.rom_a },
          { value: Constants.dog_class_element_level.rom_b, id: Constants.dog_class_element_level.rom_b }
        ]);
      }
    } else if (_showType === Constants.show_type.obedience) {
      if (event && event.sanctioning_club === Constants.sanctioning_club.AKC) {
        setLevels([
          { value: Constants.dog_class_element_level.beginner_novice_a, id: Constants.dog_class_element_level.beginner_novice_a },
          { value: Constants.dog_class_element_level.beginner_novice_b, id: Constants.dog_class_element_level.beginner_novice_b },
          { value: Constants.dog_class_element_level.preferred_novice, id: Constants.dog_class_element_level.preferred_novice },
          { value: Constants.dog_class_element_level.graduate_novice, id: Constants.dog_class_element_level.graduate_novice },
          { value: Constants.dog_class_element_level.novice_a, id: Constants.dog_class_element_level.novice_a },
          { value: Constants.dog_class_element_level.novice_b, id: Constants.dog_class_element_level.novice_b },
          { value: Constants.dog_class_element_level.open_a, id: Constants.dog_class_element_level.open_a },
          { value: Constants.dog_class_element_level.open_b, id: Constants.dog_class_element_level.open_b },
          { value: Constants.dog_class_element_level.preferred_open, id: Constants.dog_class_element_level.preferred_open },
          { value: Constants.dog_class_element_level.graduate_open, id: Constants.dog_class_element_level.graduate_open },
          { value: Constants.dog_class_element_level.utility_a, id: Constants.dog_class_element_level.utility_a },
          { value: Constants.dog_class_element_level.utility_b, id: Constants.dog_class_element_level.utility_b },
          { value: Constants.dog_class_element_level.preferred_utility, id: Constants.dog_class_element_level.preferred_utility },
          { value: Constants.dog_class_element_level.versatility, id: Constants.dog_class_element_level.versatility },
          { value: Constants.dog_class_element_level.veteran, id: Constants.dog_class_element_level.veteran },
          //{ value: Constants.dog_class_element_level.abi_brace, id: Constants.dog_class_element_level.abi_brace },
          { value: Constants.dog_class_element_level.brace, id: Constants.dog_class_element_level.brace },
          { value: Constants.dog_class_element_level.team, id: Constants.dog_class_element_level.team },
          { value: Constants.dog_class_element_level.wc_novice, id: Constants.dog_class_element_level.wc_novice },
          { value: Constants.dog_class_element_level.wc_open, id: Constants.dog_class_element_level.wc_open },
          { value: Constants.dog_class_element_level.wc_utility, id: Constants.dog_class_element_level.wc_utility },
          { value: Constants.dog_class_element_level.udx, id: Constants.dog_class_element_level.udx }
        ]);
      } else {
        setLevels([
          { value: Constants.dog_class_element_level.pre_novice, id: Constants.dog_class_element_level.pre_novice},
          { value: Constants.dog_class_element_level.beginner_novice, id: Constants.dog_class_element_level.beginner_novice},
          { value: Constants.dog_class_element_level.beginner_novice_a, id: Constants.dog_class_element_level.beginner_novice_a},
          { value: Constants.dog_class_element_level.beginner_novice_b, id: Constants.dog_class_element_level.beginner_novice_b},
          { value: Constants.dog_class_element_level.preferred_novice, id: Constants.dog_class_element_level.preferred_novice},
          { value: Constants.dog_class_element_level.novice_a, id: Constants.dog_class_element_level.novice_a},
          { value: Constants.dog_class_element_level.novice_b, id: Constants.dog_class_element_level.novice_b},
          { value: Constants.dog_class_element_level.novice_c, id: Constants.dog_class_element_level.novice_c},
          { value: Constants.dog_class_element_level.advance_novice, id: Constants.dog_class_element_level.advance_novice},
          { value: Constants.dog_class_element_level.graduate_novice, id: Constants.dog_class_element_level.graduate_novice},
          { value: Constants.dog_class_element_level.open_a, id: Constants.dog_class_element_level.open_a},
          { value: Constants.dog_class_element_level.open_b, id: Constants.dog_class_element_level.open_b},
          { value: Constants.dog_class_element_level.advance_open, id: Constants.dog_class_element_level.advance_open},
          { value: Constants.dog_class_element_level.preferred_open, id: Constants.dog_class_element_level.preferred_open},
          { value: Constants.dog_class_element_level.graduate_open, id: Constants.dog_class_element_level.graduate_open},
          { value: Constants.dog_class_element_level.utility_a, id: Constants.dog_class_element_level.utility_a},
          { value: Constants.dog_class_element_level.utility_b, id: Constants.dog_class_element_level.utility_b},
          { value: Constants.dog_class_element_level.preferred_utility, id: Constants.dog_class_element_level.preferred_utility}
        ]);
      }
    } else if (_showType === Constants.show_type.weight_pull) {
      setLevels([
        { value: Constants.dog_class_element_level.division_a, id: Constants.dog_class_element_level.division_a},
        { value: Constants.dog_class_element_level.division_b, id: Constants.dog_class_element_level.division_b},
        { value: Constants.dog_class_element_level.veteran, id: Constants.dog_class_element_level.veteran}
      ]);
    } else if (_showType === Constants.show_type.conformation && event && event.sanctioning_club === Constants.sanctioning_club.AKC) {
      setLevels([
        { value: Constants.dog_class_element_level.akc_6_9_month_puppy, id: Constants.dog_class_element_level.akc_6_9_month_puppy },
        { value: Constants.dog_class_element_level.akc_9_12_month_puppy, id: Constants.dog_class_element_level.akc_9_12_month_puppy },
        { value: Constants.dog_class_element_level.akc_12_18_month_puppy, id: Constants.dog_class_element_level.akc_12_18_month_puppy },
        { value: Constants.dog_class_element_level.akc_under_12_month_bred_by_exhibitor, id: Constants.dog_class_element_level.akc_under_12_month_bred_by_exhibitor },
        { value: Constants.dog_class_element_level.akc_adult_bred_by_exhibitor, id: Constants.dog_class_element_level.akc_adult_bred_by_exhibitor },
        { value: Constants.dog_class_element_level.akc_amateur_owner_handler, id: Constants.dog_class_element_level.akc_amateur_owner_handler },
        { value: Constants.dog_class_element_level.akc_american_bred, id: Constants.dog_class_element_level.akc_american_bred },
        { value: Constants.dog_class_element_level.akc_open, id: Constants.dog_class_element_level.akc_open },
        { value: Constants.dog_class_element_level.akc_best_of_breed, id: Constants.dog_class_element_level.akc_best_of_breed },
        { value: Constants.dog_class_element_level.akc_veteran_8_10_years, id: Constants.dog_class_element_level.akc_veteran_8_10_years },
        { value: Constants.dog_class_element_level.akc_veteran_10_12_years, id: Constants.dog_class_element_level.akc_veteran_10_12_years },
        { value: Constants.dog_class_element_level.akc_veteran_13_plus_years, id: Constants.dog_class_element_level.akc_veteran_13_plus_years },
        { value: Constants.dog_class_element_level.akc_sweepstakes, id: Constants.dog_class_element_level.akc_sweepstakes },
        { value: Constants.dog_class_element_level.akc_futurity, id: Constants.dog_class_element_level.akc_futurity }
      ]);
    } else {
      setLevels([]);
    }

    switch(showElement.id) {
      case Constants.dog_class_element.nosework_handler_descrimination:
        setLevels([
          { value: Constants.dog_class_element_level.novice, id: Constants.dog_class_element_level.novice },
          { value: Constants.dog_class_element_level.advanced, id: Constants.dog_class_element_level.advanced },
          { value: Constants.dog_class_element_level.excellent, id: Constants.dog_class_element_level.excellent },
          { value: Constants.dog_class_element_level.master, id: Constants.dog_class_element_level.master }
        ]);
      break;
      case Constants.dog_class_element.nosework_container:
      case Constants.dog_class_element.nosework_exterior:
      case Constants.dog_class_element.nosework_interior:
      case Constants.dog_class_element.nosework_vehicle:
        setLevels([
          { value: "None", id: "None" },
          { value: Constants.dog_class_element_level.novice, id: Constants.dog_class_element_level.novice },
          { value: Constants.dog_class_element_level.advanced, id: Constants.dog_class_element_level.advanced },
          { value: Constants.dog_class_element_level.superior, id: Constants.dog_class_element_level.superior },
          { value: Constants.dog_class_element_level.master, id: Constants.dog_class_element_level.master },
          { value: Constants.dog_class_element_level.elite, id: Constants.dog_class_element_level.elite }
        ]);
      break;
    }

    if (show.id !== "None" && event) {
      getEventScores(
        event._id,
        {
          query: generateSearchQuery(searchQuery)
        },
        show.id.split(":")[0],
        showElement.id,
        showLevel.id,
        showSection.id,
        resultSkip,
        show.id.split(":")[1]
      );
    } else {
      setResults([]);
    }
  }, [show, showElement, showLevel, showSection, event, getEventScores, resultSkip, generateSearchQuery, searchQuery]);

  const renderDiscounts = () => {
    return(<>
      <div className="settingsTab">
        <div className="description">
          <div className="title">Discounts</div>
          <div className="text">Add percentage-based or dollar-based discounts to your event using discount codes.</div>
        </div>
        <div className="inputs">
            <Table
              headers={discountTableHeaders}
              data={discountData}
              emptyMsg={<div className="emptyTable"><span>No Discounts Found</span></div>}
              action={{ text: "Add Discount", onClick: () => { setModalContent("discountModal"); } }}
              onPageNext={() => {
                  if (event) {
                    getEventDiscounts(event._id, {}, discSkip + 10);
                    setDiscSkip(discSkip + 10);
                  }
                }
              }
              onPagePrevious={() => {
                  if (event) {
                    getEventDiscounts(event._id, {}, discSkip - 10 >= 0 ? discSkip - 10 : 0);
                    setDiscSkip(discSkip + 10);                
                  }
                }
              }
              limit={10}
              skip={discSkip}
            />
        </div>
      </div>
    </>);
  }

  const renderRegistrants = () => {
    return(<>
      <div className="options registrants">
        <div className="actionBar">
          <div className="tableActions">
            <Button onClick={() => {
              if (event) {
                download.pushDownload({
                  type: "armband",
                  eventId: event._id
                });              
              }

            }}>Export</Button>
            
            <Button onClick={() => {
              if (event) {
                download.pushDownload({
                  type: "print_registration",
                  eventId: event._id
                });
              }
            }}>Download Forms</Button>

            <Button onClick={() => {
              if (event) {
                setModalContent("sendMessage");
              }
            }}>Send Message</Button>
          </div>
          <div className="searchInput">
            <Input className="query-input" placeholder="Search dog name, breed, armband, owner name, email, etc." type="text" onChange={(e) => { setSearchQuery(e.target.value) }} />
          </div>
        </div>
      </div>
      <div className="tableOnly registrants">
        <div className="inputs">
          <Table
            headers={registrantHeaders}
            data={registrants}
            onPageNext={() => {
              if (event) {
                getEventRegistrants(
                  event._id,
                  {
                    sort: [{
                      attribute_name: "show_type",
                      sort: "asc"
                    }, {
                      attribute_name: "created_at",
                      sort: "asc"
                    }],
                    query: generateSearchQuery(searchQuery)
                  },
                  regSkip + 10
                );

                setRegSkip(regSkip + 10);
              }
            }}
            onPagePrevious={() => {
              if (event) {
                getEventRegistrants(
                  event._id,
                  {
                  sort: [{
                      attribute_name: "show_type",
                      sort: "asc"
                    }, {
                      attribute_name: "created_at",
                      sort: "asc"
                    }],
                    query: generateSearchQuery(searchQuery)
                  },
                  regSkip - 10 >= 0 ? regSkip - 10 : 0
                );
                setRegSkip(regSkip + 10);
              }
            }}
            limit={10}
            skip={regSkip}
          />
        </div>
      </div>
    </>);
  }

  const renderResults = () => {
    return(<>
      <div className="options results">
        <div className="actionBar">
          <div className="tableActions">
            <Dropdown value={show} onChange={(e: any, value: IOption) => { if (value !== show) { setShowElement({ id: "None", value: "None" }); setShowSection({ id: "None", value: "None" }); setShowLevel({ id: "None", value: "None" }); } setShow(value); }} label="Show" options={shows} placeholder="Select a Show" />
            <Dropdown value={showElement} disabled={showElements.length === 0} onChange={(e: any, value: IOption) => { setShowElement(value); }} label="Element" options={showElements} placeholder="Select an Element" />
            <Dropdown value={showLevel} disabled={levels.length === 0} onChange={(e: any, value: IOption) => { setShowLevel(value); }} label="Level" options={levels} placeholder="Select a Level" />
            <Dropdown value={showSection} disabled={show.id.split(":")[1] !== Constants.show_type.nosework} onChange={(e: any, value: IOption) => { setShowSection(value); }} label="Section" options={sections} placeholder="Select a Section" />
          </div>
          <div className="searchInput">
            <div className="downloadDrop">
              <Button disabled={show.id === "None"} className="downloadBtn" onClick={() => { setShowResultDownloads(!showResultDownloads); }}><i className="icon download"></i></Button>
              {showResultDownloads && <ul className="downloadActions">
                <li onClick={() => {
                  if (event) {
                    download.pushDownload({
                      type: "checkin",
                      eventId: event._id,
                      showId: show.id.split(":")[0],
                      showElement: showElement.id,
                      showLevel: showLevel.id,
                      showSection: showSection.id
                    });

                    setShowResultDownloads(false);
                  }
                }}>Download Check-in List</li>
                <li onClick={() => {
                  if (event) {
                    download.pushDownload({
                      type: "scoresheets",
                      eventId: event._id,
                      showId: show.id.split(":")[0],
                      showElement: showElement.id,
                      showLevel: showLevel.id,
                      showSection: showSection.id
                    });

                    setShowResultDownloads(false);
                  }
                }}>Download Scoresheets</li>
                <li onClick={() => {
                  if (event) {
                    setModalContent("ribbonSkipModal");
                    setShowResultDownloads(false);
                  }
                }}>Download Ribbon Labels</li>
                <li onClick={() => {
                  if (event) {
                    download.pushDownload({
                      type: "results",
                      eventId: event._id,
                      showId: show.id.split(":")[0],
                      showElement: showElement.id,
                      showLevel: showLevel.id,
                      showSection: showSection.id
                    });

                    setShowResultDownloads(false);
                  }
                }}>Download Results</li>
                <li onClick={() => {
                  if (event) {
                    download.pushDownload({
                      type: "judge_books",
                      eventId: event._id,
                      showId: show.id.split(":")[0],
                      showElement: showElement.id,
                      showLevel: showLevel.id,
                      showSection: showSection.id
                    });

                    setShowResultDownloads(false);
                  }
                }}>Download Judges Books</li>
              </ul>}
            </div>
            <Input className="query-input" placeholder="Search call name, breed, armband, etc." type="text" onChange={(e) => { setSearchQuery(e.target.value) }} />
            <div className="emailWrap">
              <Button className="emailBtn" onClick={() => { setModalContent("emailConfirmModal"); }}><i className="icon email"></i></Button>
            </div>
          </div>
        </div>
      </div>
      <div className="tableOnly results">
        <div className="inputs">
          <Table
            headers={resultsHeaders}
            data={results}
            emptyMsg={<div className="emptyTable"><span>{show.id === "None" || showElement.id === "None" || showLevel.id === "None" || showSection.id === "None" ? "Select a Show, Element, and Level/Division to view results" : "No Results"}</span></div>}
            onPageNext={() => {
                if (event) {
                  getEventScores(
                    event._id,
                    {
                      query: generateSearchQuery(searchQuery)
                    },
                    show.id.split(":")[0],
                    showElement.id,
                    showLevel.id,
                    showSection.id,
                    resultSkip + 10,
                    show.id.split(":")[1]
                  );
                  setResultSkip(resultSkip + 10);
                }
              }
            }
            onPagePrevious={() => {
                if (event) {
                  getEventScores(
                    event._id,
                    {
                      query: generateSearchQuery(searchQuery)
                    },
                    show.id.split(":")[0],
                    showElement.id,
                    showLevel.id,
                    showSection.id,
                    resultSkip - 10 >= 0 ? resultSkip - 10 : 0,
                    show.id.split(":")[1]
                  );
                  setResultSkip(resultSkip + 10);
                }
              }
            }
            limit={10}
            skip={resultSkip}
          />
        </div>
      </div>
    </>);
  }

  const renderTransactions = () => {
    return(<>
      <div className="options registrants">
        <div className="actionBar">
            <div className="tableActions">
                <Button onClick={() => {
                  if (event) {
                    download.pushDownload({
                      type: "transactions",
                      eventId: event._id
                    });              
                  }

                }}>Export</Button>
            </div>
          </div>
        <div className="inputs">
            <Table
              headers={transHeaders}
              data={transData}
              emptyMsg={<div className="emptyTable"><span>No Transactions Found</span></div>}
              onPageNext={() => {
                  if (event) {
                    getEventTransactions(event._id, {}, transSkip + 10);
                    setTransSkip(transSkip + 10);
                  }
                }
              }
              onPagePrevious={() => {
                  if (event) {
                    getEventTransactions(event._id, {}, transSkip - 10 >= 0 ? transSkip - 10 : 0);
                    setTransSkip(transSkip + 10);                
                  }
                }
              }
              limit={10}
              skip={transSkip}
            />
        </div>
        
      </div>
    </>);
  }

  const getModalContent = () => {
    switch(modalContent) {
      case "discountModal":
        return <DiscountForm
            eventId={event?._id || ""}
            onFinish={() => {
              setModalContent("");
              toggle();

              if (event) {
                getEventDiscounts(event._id, {});
              }}
            }
          />;
      case "enterResultsModal":
        return <EnterResults
            eventId={event?._id || ""}
            showType={show.id.split(":")[1]}
            result={result}
            onSave={() => {
              toggle();
              setModalContent("");

              if (event) {
                getEventScores(
                  event._id,
                  {
                    query: generateSearchQuery(searchQuery)
                  },
                  show.id.split(":")[0],
                  showElement.id,
                  showLevel.id,
                  showSection.id,
                  resultSkip,
                  show.id.split(":")[1]
                );
              }
            }}
            onClose={() => {
              toggle();
              setModalContent("")
            }}
          />;
    case "editRegModal":
      if (!eventRegistration) {
        return <div>No Registration Provided</div>;
      } else {
        return <EditRegistration event={event || undefined} registration={eventRegistration} onComplete={() => {
            toggle();
            setModalContent("");

            if (event) {
              getEventRegistrants(
                event._id,
                {
                  sort: [{
                    attribute_name: "show_type",
                    sort: "asc"
                  }, {
                    attribute_name: "created_at",
                    sort: "asc"
                  }],
                  query: generateSearchQuery(searchQuery)
                },
                regSkip
              );
            }
          }
        } />;
      }
    case "changeModal":
      if (!eventRegistration) {
        return <div>No Registration Provided</div>;
      } else {
        return <ChangeElements
          event={event || undefined}
          registration={eventRegistration}
          onComplete={() => {
            toggle();
            setModalContent("");
          }}
          onClose={() => {
            toggle();
            setModalContent("");
          }}
        />
      }
    case "moveUpModal":
      if (changeRegistration) {
          return <MoveUp
            event={event || undefined}
            registration={changeRegistration}
            onComplete={() => {
              toggle();
              setModalContent("");

              if (event) {
                getEventRegistrants(
                  event._id,
                  {
                    sort: [{
                      attribute_name: "show_type",
                      sort: "asc"
                    }, {
                      attribute_name: "created_at",
                      sort: "asc"
                    }],
                    query: generateSearchQuery(searchQuery)
                  },
                  regSkip
                );
              }
            }
          }
          onClose={() => {
              toggle();
              setModalContent("");
            }
          }
          />;
      } else {
        return <div>No Registration Provided</div>;
      }
    case "ribbonSkipModal":
      return <RibbonSkip onSubmit={(skipAmt: number) => {
        if (event) {
          download.pushDownload({
            type: "ribbons",
            eventId: event._id,
            showId: show.id.split(":")[0],
            showElement: showElement.id,
            showLevel: showLevel.id,
            showSection: showSection.id,
            labelsToSkip: skipAmt
          });

          toggle();
          setModalContent("");
      }}} />;
    case "emailConfirmModal":
      return <div className="bulkEmailResults">
        <div className="title">Bulk Email Results</div>
        <p>You are about to bulk email <i>all</i> registrants to this event. <i>It is recommended you only do this once</i>. You should completely enter <i>all results to all shows</i> before doing this, have you completed entering all results?</p>
        <div className="actions">
          <Button onClick={() => { toggle(); setModalContent(""); }}>Cancel</Button>
          <Button isLoading={isEmailing} disabled={isEmailing} onClick={() => { bulkEmailResults(); }}>Yes, Bulk Email Registrants</Button>
        </div>
      </div>;
    case "sendMessage":
      return <SendMessage  handleSendMessage={sendMessage}/>
    case "emailSingleModal":
      if (resultEmail) {
        return <div className="bulkEmailResults">
          <div className="title">Email Results</div>
          <p>You are about to email results to <i>{resultEmail.owner_name}</i> for <i>{resultEmail.show_type} {resultEmail.show_name}</i>. It is recommended you only do this once. Are you sure you want to email them now? (You can bulk email results once you've completed entering all results.)</p>
          <div className="actions">
            <Button onClick={() => { toggle(); setModalContent(""); setResultEmail(undefined); }}>Cancel</Button>
            <Button isLoading={isEmailing} disabled={isEmailing} onClick={() => { emailResults(); }}>Yes, Email Results</Button>
          </div>
        </div>;
      }
      else {
        return <div></div>;
      }
    default: 
      return <div></div>;
    }
  }

  return (
    <div className="EventManage ManagePage">
      {event && <Helmet>
        <title>{event?.title} - Manage Event</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>}
      <div className="accountInner">
        <div className="breadcrumb"><div className="path"><Link to={"/e/" + eid}>{event ? event.title : "Event"}</Link> / </div><div className="current">Manage Event</div></div>
        <h1>Manage Event</h1>
        <div className="metrics">
          <div className="metricItem">
            <div className="num">{metrics.dogs}</div>
            <div className="label">Dogs Registered</div>
          </div>
          <div className="metricItem">
            <div className="num">{metrics.entries}</div>
            <div className="label">Entries</div>
          </div>
          <div className="metricItem">
            <div className="num">{formatter.format(metrics.revenue)}</div>
            <div className="label">Total Revenue</div>
          </div>
        </div>
        <TabContent setTab={setTab} activeTab={activeTab} tabs={tabs}>
          {renderRegistrants()}
          {renderDiscounts()}
          {renderResults()}
          {renderTransactions()}
        </TabContent>
      </div>
      <Modal
        className={modalContent}
        isShown={isShown}
        hide={toggle}
        modalContent={getModalContent()}
      />
      {refundInfo !== undefined && <Modal className="refundModal" isShown={refundInfo !== undefined} hide={() => { setRefundInfo(undefined); }} modalContent={
        <Refund
          transaction={refundInfo}
          onRefund={() => {
              setRefundInfo(undefined);

              if (event) {
                getEventTransactions(event._id, {}, transSkip + 10);
                apiService.getEventMetrics(event._id);
              }
            }
          }
          onCancel={() => {
              setRefundInfo(undefined);
            }
          }
        />
      } />}
      {removeDog !== undefined && <Modal
        className="removeDogModal"
        isShown={removeDog !== undefined}
        hide={() => {
          setRemoveDog(undefined);
        }}
        modalContent={
          <Confirm
            message={"Are you sure you want to remove " + removeDog.dog.call_name + " from " + removeDog.show_type + " " + removeDog.show_name + "? This won't refund the transaction, you can refund on the Transactions tab."}
            onConfirm={() => {
              if (event) {
                setIsRemovingDog(true);

                apiService.removeDogFromShow(event._id, removeDog.show_id, removeDog.dog.dog_id).then((response) => {
                  toast.show({
                    title: "Remove Dog",
                    content: "Successfully removed " + removeDog.dog.call_name + " from " + removeDog.show_type + " " + removeDog.show_name,
                    duration: 10000,
                    type: "success"
                  });

                  getEventRegistrants(
                    event._id,
                    {
                      sort: [{
                        attribute_name: "show_type",
                        sort: "asc"
                      }, {
                        attribute_name: "created_at",
                        sort: "asc"
                      }],
                      query: generateSearchQuery(searchQuery)
                    },
                    regSkip
                  );

                  apiService.getEventMetrics(event._id);

                  setRemoveDog(undefined);
                }).catch((e: AxiosError) => {
                  toast.show({
                    title: "Remove Dog",
                    content: "Unable to remove dog",
                    duration: 10000,
                    errorDetails: e,
                    type: "fail"
                  });
                }).finally(() => {
                  setIsRemovingDog(false);
                });
              }
            }}
            onCancel={() => { setRemoveDog(undefined); }}
            buttonsDisabled={isRemovingDog}
          />
        } />
      }
    </div>
  );
};

export default EventManage;