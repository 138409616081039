import { Constants } from "@eagerdog/constants";
import { IEventRegisterRow } from "../../../services/helper.service.js";

export namespace AKCLureCoursing {
  export const form: IEventRegisterRow[] = [
    {
      allowDuplicates: false,
      inputs: [
        {
          type: "checkbox",
          label: Constants.dog_class_element.lure_coursing,
          multiSelect: false,
          options: [
            {
              value: Constants.dog_class_element_level.akc_open,
              id: Constants.dog_class_element_level.akc_open,
            },
            {
              value: Constants.dog_class_element_level.special,
              id: Constants.dog_class_element_level.special,
            },
            {
              value: Constants.dog_class_element_level.veteran,
              id: Constants.dog_class_element_level.veteran,
            },
            {
              value: Constants.dog_class_element_level.junior_coursing,
              id: Constants.dog_class_element_level.junior_coursing,
            },
            {
              value: Constants.dog_class_element_level.qualified_coursing,
              id: Constants.dog_class_element_level.qualified_coursing,
            }
          ],
        },
      ],
    },
  ];
}