import React, { useState, useEffect } from "react";
import { Constants } from "@eagerdog/constants";
import { useNavigate, useLocation } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroller";
import { AxiosError } from "axios";
import { Helmet } from "react-helmet";
import moment from "moment";

import { IUser, IEvent } from "@eagerdog/interfaces";

import { helperService } from "../../services/helper.service";
import { apiService } from "../../services/api.service";
import { IEventSummary } from "@eagerdog/interfaces";

import { Modal, useModal } from "../../components/Modal/Modal";
import EventCard from "../../components/EventCard/EventCard";
import Congrats from "../../components/Congrats/Congrats";
import EventForm from "../../components/StepForm/EventForm/EventForm";
import Button from "../../components/Button/Button";
import TabContent, { ITab, useTabContent } from "../../components/TabContent/TabContent";

import { toast } from "../../components/Toast/ToastManager";

import "./Events.scss";
import { IQueryAttributeGroup } from '@eagerdog/interfaces';

interface IProps {}

const Events: React.FC<IProps> = (props) => {
  const user:IUser = helperService.getUser();
  const navigate = useNavigate();

  const { setTab, activeTab } = useTabContent();

  const tabs:ITab[] = [
    { name: "Upcoming", actions: [] },
    { name: "Past", actions: [] }
  ];

  const { isShown, toggle } = useModal();

  const limit:number = 6;
  const loaderAmt:number[] = [1, 2, 3, 4, 5, 6];

  const [skip, setSkip] = useState<number>(0);
  const [hasMore, setHasMore] = useState<boolean>(true);

  const [pastEvents, setPastEvents] = useState<IEventSummary[]>([]);
  const [events, setEvents] = useState<IEventSummary[]>([]);

  const [loading, setLoading] = useState<boolean>(true);
  const [modalContent, setModalContent] = useState<string>();

  const [newEventUrl, setNewEventUrl] = useState<string>("");

  const pModalContent = helperService.usePrevious(modalContent);

  const getEvents = () => {
    if (hasMore) {
      let query: IQueryAttributeGroup = {
        $and: []
      };

      if (user.user_type === "club_manager") {
        apiService.getCurrentUserClubs().then((response) => {
          const clubId = response._id;
          if (activeTab === 0) {
            apiService.getClubEvents(clubId, {$limit: limit, $skip: skip, sort: [{ attribute_name: 'start_date', sort: "asc" } ]}).then((response) => {
              if (response.length < 6) {
                setHasMore(false);
              }

              let _pastEvents:IEventSummary[] = [];
              let _events:IEventSummary[] = [...events];

              for (let i in response) {

                if (moment(response[i].end_date).add('days', 1) < moment(Date.now())) {
                  _pastEvents.push(response[i]);
                } else {
                  _events.push(response[i]);
                }
              }
      
              setSkip(skip + 6);

              _pastEvents = _pastEvents.reverse();

              setPastEvents([..._pastEvents, ...pastEvents]);
              setEvents(_events);
      
              if (loading) {
                setLoading(false);
              }
            }).catch((e: AxiosError) => {
              toast.show({
                title: "Get Events",
                content: "Unable to get events",
                duration: 10000,
                errorDetails: e,
                type: "fail"
              });
            });
          }
        }).catch((e: AxiosError) => {
          toast.show({
            title: "Get Events",
            content: "Unable to get events",
            duration: 10000,
            errorDetails: e,
            type: "fail"
          });         
        });
      } else if (user.user_type === "owner") {
        apiService.getOwnerEvents({$limit: limit, $skip: skip, sort: [{ attribute_name: 'start_date', sort: "asc" } ], query: query }).then((response) => {
          if (response.length < 6) {
            setHasMore(false);
          }
  
          setSkip(skip + 6);
  
          let _events:IEventSummary[] = [...events, ...response];
          setEvents(_events);
  
          if (loading) {
            setLoading(false);
          }
        }).catch((e: AxiosError) => {
          toast.show({
            title: "Get Events",
            content: "Unable to get events",
            duration: 10000,
            errorDetails: e,
            type: "fail"
          });
        });
      } else {
        apiService.getEvents({$limit: limit, $skip: skip, sort: [{ attribute_name: 'start_date', sort: "asc" } ], query: query }).then((response) => {
          if (response.length < 6) {
            setHasMore(false);
          }
  
          setSkip(skip + 6);
  
          let _events:IEventSummary[] = [...events, ...response];
          setEvents(_events);
  
          if (loading) {
            setLoading(false);
          }
        }).catch((e: AxiosError) => {
          toast.show({
            title: "Get Events",
            content: "Unable to get events",
            duration: 10000,
            errorDetails: e,
            type: "fail"
          });
        });
      }
    }
  }

  const getModalContent = () => {
    switch (modalContent) {
      case "congratsModal":
        return <Congrats text="You made an event! Now let's create some shows for your event." url={"/e/"+ newEventUrl +"/?s=1"} urlText="Create Shows" />;
      default:
        return <EventForm onFinish={(e: IEvent) => { toggle(); navigate("/?e=" + e.handle, { replace: true }); }} />
    }
  }

  const setActionConfig =  (event: IEventSummary): { text: string, link: string } => {
    let actionLink = "/e/" + event.handle;
    let actionText = "View Event";


    if (user?.user_type === "owner") {
      if (event.status === Constants.event_status.published) {
        if (!event?.is_registered) {
          actionLink += "/register";
          actionText = "Register";
        }
      }
    }

    return { text: actionText, link: actionLink };
  }

  useEffect(() => {
    if (pModalContent !== modalContent) {
      if (modalContent !== "") {
        toggle();
      }
    } else {
      if (!isShown) {
        setModalContent("");
      }
    }
  }, [isShown, modalContent, pModalContent, setModalContent, toggle]);

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const newEvent = searchParams.get("e");

  useEffect(() => {
    if (newEvent !== null) {
      setNewEventUrl(newEvent);

      setModalContent("congratsModal");

      window.history.pushState('', '', '/');
    }
  }, [newEvent]);

  return (
    <div className="Events">
      <Helmet>
        <title>Events Listing</title>
        <meta name="description" content="Upcoming Fetch, Fast Cat, Nosework, Rally Obedience, and Conformation kennel club events running on Eager Dog." />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <div className="inner">
        <div className="title">
          <h1>Events</h1>
        </div>
        {user.user_type === "club_manager" && <TabContent setTab={setTab} activeTab={activeTab} tabs={tabs}>
          <>
            <InfiniteScroll className="list" threshold={0} pageStart={0} loadMore={getEvents} hasMore={hasMore} loader={<div className="loading" key={0}></div>}>
              {events.map((event: IEventSummary, i: number) => {
                const actionConfig = setActionConfig(event);
                return(<EventCard event={event} key={"event-" + i} action={actionConfig} isAdmin={user?.user_type === "club_manager"} />);
              })}
              {events.length % 3 !== 0 && <div className="eventCardPlaceholder"></div>}
            </InfiniteScroll>
          </>
          <>
            <InfiniteScroll className="list" threshold={0} pageStart={0} loadMore={getEvents} hasMore={hasMore} loader={<div className="loading" key={0}></div>}>
              {pastEvents.map((event: IEventSummary, i: number) => {
                const actionConfig = setActionConfig(event);
                return(<EventCard event={event} key={"event-" + i} action={actionConfig} isAdmin={user?.user_type === "club_manager"} />);
              })}
              {pastEvents.length % 3 !== 0 && <div className="eventCardPlaceholder"></div>}
            </InfiniteScroll>
          </>
        </TabContent>}
        {user.user_type !== "club_manager" && <InfiniteScroll className="list" threshold={0} pageStart={0} loadMore={getEvents} hasMore={hasMore} loader={<div className="loading" key={0}></div>}>
          {events.map((event: IEventSummary, i: number) => {
            const actionConfig = setActionConfig(event);
            return(<EventCard event={event} key={"event-" + i} action={actionConfig} isAdmin={user?.user_type === "club_manager"} />);
          })}
          {events.length % 3 !== 0 && <div className="eventCardPlaceholder"></div>}
        </InfiniteScroll>}
        {!loading && events.length === 0 &&
          <div className="no-events"><span>{user?.user_type === "club_manager" ? `You don't have any events planned, yet.` : `There aren't any upcoming events at the moment.`}</span>
          {user?.user_type === "club_manager" && <Button onClick={() => { setModalContent("eventForm"); }}>Create an Event</Button>}
          </div>
        }
        {loading && <div className="list">
        {loaderAmt.map((loader:number, index:number) => {
          return(
            <div key={index} className="eventCard skeleton">
              <div className="details half"></div>
              <div className="details big"></div>
              <div className="details image"></div>
              <div className="details spacer"></div>
              <div className="details"></div>
              <div className="details half"></div>
              <div className="details half"></div>
              <div className="details half"></div>
            </div>
          );
        })}
        </div>}
      </div>
      <Modal
        className={modalContent}
        isShown={isShown}
        hide={toggle}
        modalContent={getModalContent()}
      />
    </div>
  );
};

export default Events;