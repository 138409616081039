// ---------------
export const Constants = {
  //
  auth: {
    access_jwt_strategy_name: "access",
    refresh_jwt_strategy_name: "refresh",
  },

  auth_type: {
    none: "none",
    password: "password",
  },

  cookies: {
    refresh_token: "x-eagerdog-refresh-token",
  },

  user_type: {
    owner: "owner",
    club_manager: "club_manager",
  },

  dog_coowner_type: {
    owner: "owner",
    junior: "junior",
    handler: "handler",
  },

  token_type: {
    password: "password",
    refresh: "refresh",
    registration: "registration",
    email: "email",
    password_reset: "password_reset",
  },

  notification_events: {
    email_verification_token: "email-verification-token",
    email_welcome: "email-welcome",
    email_new_registration_dog_owner: "email_new_registration_dog_owner",
    email_new_registration_kennel_club: "email_new_registration_kennel_club",
    email_send: "email-send",

    email_password_reset: "email_password_reset",
    email_results: "email_results",

    email_new_subscription: "email_new_subscription",
    email_send_message: "email_send_message",
    email_new_arm_number_per_show_type: "email_new_arm_number_per_show_type",
  },

  notification_transport_type: {
    email: "email",
    sms: "sms",
  },

  object_type: {
    club: "club",
    dog: "dog",
    event: "event",
  },

  registration_number_type: {
    ukc_registered: "D",
    ukc_temporary: "TL",
    ukc_performance: "PL",
    akc_registered: "AKC",
    akc_pal: "PAL/ILP NO",
    akc_foreign_reg: "Foreign Reg.",

    abi_ukc: "UKC",
    abi_akc: "AKC",
    abi_ckc: "CKC",
    abi_other: "Other",
  },

  object_permissions: {
    manage: "manage",
  },

  event_status: {
    draft: "draft",
    published: "published",
    in_progress: "in_progress",
    complete: "complete",
    archived: "archived",
  },

  transaction_status: {
    pending: "pending",
    success: "success",
    failed: "failed",
  },

  sanctioning_club: {
    UKC: "UKC",
    AKC: "AKC",
    ABI: "ABI",
    CKC: "CKC",
    other: "Other",
  },

  show_name: {
    trial_1: "Trial 1",
    trial_2: "Trial 2",
    trial_3: "Trial 3",
    trial_4: "Trial 4",
    show_1: "Show 1",
    show_2: "Show 2",
    show_3: "Show 3",
    show_4: "Show 4",
  },

  show_type: {
    conformation: "Conformation",
    nosework: "Nosework",
    //performance: "Performance",
    obedience: "Obedience",
    rally_obedience: "Rally Obedience",
    agility: "Agility",
    weight_pull: "Weight Pull",
    urban_rat_race: "Urban Rat Race",
    country_rat_race: "Country Rat Race",
    scentwork: "Scentwork",

    fast_cat: "Fast Cat",
    fetch: "Fetch",
    lure_coursing: "Lure Coursing",
    precision_coursing: "Precision Coursing",
  },

  show_run_time_status: {
    available: "available",
    pending: "pending",
    reserved: "reserved",
  },

  account_type: {
    custom: "custom",
  },

  dog_sex: {
    male: "Male",
    female: "Female",
  },

  nq_reason: {
    incorrect_call: "Incorrect Call by Handler",
    false_alert: "False Alert by Dog",
    timed_out: "Timed Out",
    exposed_hide: "Exposed Hide or Distraction Prior to Alert",
    miss: "Miss of Non-indication",
    too_many_faults: "Too Many Faults",
  },

  dog_registration_status: {
    pending: "pending",
    active: "active",
  },

  dog_class_section: {
    none: "None",
    nosework_a: "A",
    nosework_b: "B",
    on_leash: "On Leash",
    unleashed: "Unleashed",
  },

  dog_class_element: {
    nosework_container: "Container",
    nosework_exterior: "Exterior",
    nosework_interior: "Interior",
    nosework_vehicle: "Vehicle",
    nosework_handler_descrimination: "Handler Disc.",

    conformation: "Conformation",
    conformation_altered: "Altered Conformation",
    conformation_non_licensed: "Non-Licensed Classes",

    novice_puppy: "Novice Puppy",
    veteran: "Veteran",
    brood_bitch_stud_dog: "Brood Bitch/Stud Dog",
    brace: "Brace",
    total_dog: "Total Dog",
    other: "Other",

    abi_best_of_breed: "Best of Breed",
    abi_bred_by_exibitor: "Bred by Exibitor",

    akc_sweepstakes: "Sweepstakes",
    akc_futurity: "Futurity",
    akc_junior_showmanship: "JR. Showmanship", //TODO

    performance_rally_obedience: "Rally Obedience",
    performance_obedience: "Obedience",
    performance_agility: "Agility",
    performance_non_licensed_obedience: "Non-Licensed Obedience",
    performance_weight_pull: "Weight Pull",
    performance_urban_rat_race: "Urban Rat Race",
    performance_country_rat_race: "Country Rat Race",

    scentwork_container: "Container",
    scentwork_interior: "Interior",
    scentwork_exterior: "Exterior",
    scentwork_buried: "Buried",
    scentwork_handler_disc: "Handler Discrimination",
    scentwork_detective: "Detective",

    tubes: "Tubes",
    unobstructed: "Unobstructed",
    obstructed: "Obstructed",
    fantastic_five: "Fantastic Five",
    tempting_ten: "Tempting Ten",

    beginner_a: "Beginner A",
    beginner_b: "Beginner B",
    intermediate_a: "Intermediate A",
    intermediate_b: "Intermediate B",
    excellent: "Excellent",

    fast_cat: "Fast Cat",
    fetch: "Fetch",

    //UKC junior showmanship
    junior_showmanship: "Junior Showmanship",

    //UKC, AKC Lure Coursing
    lure_coursing: "Lure Coursing",

    // UKC
    precision_coursing: "Precision Coursing",
    pc_division: "PC Division",
  },

  dog_class_element_level: {
    // nosework
    novice: "Novice",
    advanced: "Advanced",
    superior: "Superior",
    master: "Master",
    elite: "Elite",
    excellent: "Excellent",

    //Conformation (UKC)
    puppy: "Puppy (6 months-under 1 year)",
    junior: "Junior (1 year-under 2 years)",
    adult: "Adult (2 years & over)",
    open: "Open (6 months & over)",
    breader_handler: "Breeder & Handler (6 months & over shown by breeder)",
    champion: "Champion (CH)",
    grand_champion: "Grand Champion (GRCH)",

    // Conformation (UKC) - non-licensed classes
    novice_puppy: "Novice Puppy",
    veteran: "Veteran",
    brood_bitch_stud_dog: "Brood Bitch/Stud Dog",
    brace: "Brace",
    total_dog: "Total Dog",
    other: "Other",

    //Conformation (AKC) - regular classes
    akc_6_9_month_puppy: "6-9 Month Puppy",
    akc_9_12_month_puppy: "9-12 Month Puppy",
    akc_12_18_month_puppy: "12-18 Month Puppy",
    //akc_12_18_month_puppy: "12-18 Month Puppy",
    //akc_novice_or_amateur_handler: "Novice or Amateur-Owner-Handler",
    akc_under_12_month_bred_by_exhibitor: "Under 12 Month Bred-by-Exhibitor",
    akc_adult_bred_by_exhibitor: "Adult Bred-by-Exhibitor",
    akc_amateur_owner_handler: "Amateur-Owner-Handler",
    akc_american_bred: "American Bred",
    akc_open: "Open",
    akc_best_of_breed: "Best of Breed",

    akc_veteran_8_10_years: "Veteran (8-10 years)",
    akc_veteran_10_12_years: "Veteran (10-12 years)",
    akc_veteran_13_plus_years: "Veteran (13+ years)",

    //Conformation (AKC) - non-regular classes
    //This will have to be changed to accomadate sublevels
    akc_sweepstakes: "Sweepstakes",
    akc_futurity: "Futurity",

    // performance - agility
    beginner_a: "Beginner A",
    beginner_b: "Beginner B",
    intermediate_a: "Intermediate A",
    intermediate_b: "Intermediate B",
    //excellent: "Excellent", //already exists

    // Agility - Dynamic Classes
    novice_hurdles: "Novice Hurdles",
    elite_hurdles: "Elite Hurdles",
    novice_tunnels: "Novice Tunnels",
    elite_tunnels: "Elite Tunnels",
    three_of_a_kind: "3 of a Kind",

    // Agility - Division
    toy_10_inch_and_under: 'Toy (10" & Under)',
    one_over_10_to_14_inch: '1 (over 10" - 14")',
    two_over_14_to_20_inch: '2 (over 14" - 20")',
    three_over_20_inch: '3 (over 20")',

    // Agility - Jump Height
    minimum_height: "Minimum Height",
    standard_height: "Standard Height",

    // Agility - weight pull
    division_a: "Division A",
    division_b: "Division B",
    // already exists
    //veteran: "Veteran",

    // performance - rally obedience
    ro1_a: "RO1 A",
    ro1_b: "RO1 B",
    ro1_c: "RO1 C",

    ro2_a: "RO2 A",
    ro2_b: "RO2 B",

    ro3_a: "RO3 A",
    ro3_b: "RO3 B",

    rom_a: "ROM A",
    rom_b: "ROM B",

    rae: "RAE",
    raem: "RAEM",

    // performance - obedience
    pre_novice: "Pre-Novice", // UKC
    beginner_novice: "Beginner Novice", // UKC
    beginner_novice_a: "Beginner Novice A", // AKC
    beginner_novice_b: "Beginner Novice B", // AKC
    preferred_novice: "Preferred Novice", // AKC
    novice_a: "Novice A", // UKC, AKC
    novice_b: "Novice B", // UKC, AKC
    novice_c: "Novice C", // UKC
    advance_novice: "Advance Novice", // UKC
    graduate_novice: "Graduate Novice", // AKC
    open_a: "Open A", // UKC, AKC
    open_b: "Open B", // UKC, AKC
    advance_open: "Advance Open", // UKC
    preferred_open: "Preferred Open", // AKC
    graduate_open: "Graduate Open", // AKC
    utility_a: "Utility A", // UKC, AKC
    utility_b: "Utility B", // UKC, AKC
    preferred_utility: "Preferred Utility", // AKC
    wc_novice: "Wild Card Novice",
    wc_open: "Wild Card Open",
    wc_utility: "Wild Card Utility",
    udx: "UDX",

    // master: "Master",
    // elite: "Elite",
    //veteran: "Veteran",

    // performance - non-licensed obedience
    //brace: "Brace",pakc-
    pairs: "Pairs",
    precision_heeling: "Precision Heeling",
    team: "Team",
    versatility: "Versatility", // AKC performance as well
    //veteran: "Veteran",

    // ABI Conformation
    abi_baby_puppy: "Baby Puppy",
    abi_6_9_months: "6 - 9 Month",
    abi_9_12_months: "9 - 12 Month",
    abi_12_18_months: "12 - 18 Month",
    abi_bred_by_exibitor: "Bred by Exibitor",
    abi_open: "Open",
    abi_best_of_breed: "Best of Breed",
    abi_brace: "Brace",
    abi_veteran: "Veteran",
    abi_altered: "Altered",
    abi_jr_champion: "Jr. Champion",
    abi_champion: "Champion",
    abi_master_champion: "Master Champion",
    abi_grand_champion: "Grand Champion",
    abi_supreme_champion: "Supreme Champion",
    abi_ultimate_champion: "Ultimate Champion",
  

    // ABI performance - Country Rat Race
    cubs: "Cubs",
    scouts: "Scouts",
    huntsman: "Huntsman",
    huntsmaster: "Huntsmaster",
    field_master: "Field Master",

    // ABI performance - Urban Rat Race
    rookie: "Rookie",
    clever: "Clever",
    adventurer: "Adventurer",
    explorer: "Explorer",
    detective: "Detective",
    inspector: "Inspector",

    fantastic_five: "Fantastic Five",
    tempting_ten: "Tempting Ten",

    fast_cat: "Fast Cat",

    // AKC Fetch
    //novice: "Novice"
    intermediate: "Intermediate",
    //advanced: "Advanced",
    retriever: "Retriever",

    // AKC Rally Obedience
    //novice_a: "Novice A",
    //novice_b: "Novice B",
    //intermediate: "Intermediate",
    advanced_a: "Advanced A",
    advanced_b: "Advanced B",
    excellent_a: "Excellent A",
    excellent_b: "Excellent B",
    //excellent: "Excellent",
    //master: "Master",
    choice: "Choice",

    // AKC Scentwork
    scentwork_novice_a: "Novice A",
    scentwork_novice_b: "Novice B",
    scentwork_advanced: "Advanced",
    scentwork_excellent: "Excellent",
    scentwork_master: "Master",
    scentwork_detective: "Detective",

    // UKC junior showmanship
    pee_wee: "Pee Wee (2 years-under 4 years)",
    sub_junior: "Sub JR (4 years-under 6 years)",
    pre_junior: "Pre-Junior (6 years-under 8 years)",
    novice_junior: "Novice Junior (8 years-under 13 years)",
    open_junior: "Open Junior (8 years-under 13 years)",
    novice_senior: "Novice Senior (13 years-18 years)",
    open_senior: "Open Senior (13 years-18 years)",
    total_junior: "Total Junior",

    //AKC Lure Coursing
    //open: "Open",
    //veteran: "Veteran",
    special: "Special",
    junior_coursing: "Junior Coursing",
    qualified_coursing: "Qualified Coursing",

    // UKC Precision Coursing
    // novice: "Novice",
    // advanced: "Advanced",
    // superior: "Superior",
    // master: "Master",
    // elite: "Elite"
    dash: "Dash",
    steeplechase: "Steeplechase",

    // UKC Precision Coursing Non-Licensed classes
    fun_run: "Fun Run",
    puppy_dash: "Puppy Dash",

    // UKC Lure Coursing
    coursing_aptitude: "Coursing Aptitude",
    coursing_ability: "Coursing Ability",
    coursing_tested: "Coursing Tested",
    ct_bye_dog: "CT - Bye Dog",
    regular_stakes: "Regular Stakes",

    // UKC PC Division
    toy: 'T (Toy) 10" & Under',
    one: '1 over 10" - 14"',
    two: '2 over 14" - 20"',
    three: '3 over 20" - 26"',
    g: 'G (Giant) over 26"',
  },

  jump_height_type: {
    standard: "Standard",
    minimum: "Minimum",
  },

  payment_method_type: {
    card: "card",
    cash: "cash",
    etransfer: "e-transfer",
    paypal: "paypal",
    cheque: "cheque",
    other: "other",
  },

  payment_card_type: {
    unknown: "unknown",
    visa: "visa",
    mastercard: "mastercard",
    amex: "amex",
  },

  registration_kind: {
    owner: "owner",
    club: "club",
  },

  discount_type: {
    amount: "amount",
    percentage: "percentage",
  },
  // events_per_year: {
  //   "0 - 5": "0 - 5",
  //   "6 - 10": "6 - 10",
  //   "10 - 20": "10 - 20",
  //   "20+": "20+",
  // },
  incentive_type: {
    min_number_of_show_types: "min_number_of_show_types",
    number_of_entries: "number_of_entries",
    number_of_entries_per_event_number: "number_of_entries_per_event_number",
  },

  form_events: {
    show_judge_books: "show_judge_books",
    show_scoresheets: "show_scoresheets",
    event_scoresheets: "event_scoresheets",
    dog_entry_forms: "dog_entry_forms",
    transaction_entry_forms: "transaction_entry_forms",
    event_entry_forms: "event_entry_forms",
    event_registration_list: "event_registration_list",
    event_transaction_list: "event_transaction_list",
    show_type_score_list: "show_type_score_list",
    show_checkin_list: "show_checkin_list",
    show_results: "show_results",
    result_ribbon_labels: "result_ribbon_labels",
    show_type_results_email: "show_type_results_email",
    show_trial_report: "show_trial_report",
    event_catalog: "event_catalog",
    event_judge_schedule_calculator: "event_judge_schedule_calculator",
  },

  form_build_status: {
    pending: "pending",
    generating: "generating",
    complete: "complete",
    error: "error",
  },

  score_status: {
    pending: "pending",
    complete: "complete",
  },

  score_fault_type: {
    handler_error: "handler_error",
    safety_concern: "safety_concern",
    compromised_area: "compromised_area",
    incorrect_response: "incorrect_response",
    fringe_response: "fringe_response",
    aggressive_response: "aggressive_response",
  },

  score_result_type: {
    qualified: "qualified",
    not_qualified: "not_qualified",
    disqualified: "disqualified",
    excused: "excused",
    absent: "absent",
  },

  akc_fastcat_titles: {
    fcat: "FCAT",
    bcat: "BCAT",
    dcat: "DCAT",
  },

  none: "None",
};
