import { Constants } from "@eagerdog/constants";
import { IEventRegisterRow } from "../../../services/helper.service.js";

export namespace AKCObedience {
  export const form: IEventRegisterRow[] = [
    {
      allowDuplicates: false,
      inputs: [
        {
          type: "checkbox",
          label: Constants.dog_class_element.performance_obedience,
          multiSelect: true,
          options: [
            {
              value: Constants.dog_class_element_level.beginner_novice_a,
              id: Constants.dog_class_element_level.beginner_novice_a,
            },
            {
              value: Constants.dog_class_element_level.beginner_novice_b,
              id: Constants.dog_class_element_level.beginner_novice_b,
            },
            {
              value: Constants.dog_class_element_level.preferred_novice,
              id: Constants.dog_class_element_level.preferred_novice,
            },
            {
              value: Constants.dog_class_element_level.graduate_novice,
              id: Constants.dog_class_element_level.graduate_novice,
            },
            {
              value: Constants.dog_class_element_level.novice_a,
              id: Constants.dog_class_element_level.novice_a,
            },
            {
              value: Constants.dog_class_element_level.novice_b,
              id: Constants.dog_class_element_level.novice_b,
            },

            {
              value: Constants.dog_class_element_level.open_a,
              id: Constants.dog_class_element_level.open_a,
            },
            {
              value: Constants.dog_class_element_level.open_b,
              id: Constants.dog_class_element_level.open_b,
            },
            {
              value: Constants.dog_class_element_level.preferred_open,
              id: Constants.dog_class_element_level.preferred_open,
            },
            {
              value: Constants.dog_class_element_level.graduate_open,
              id: Constants.dog_class_element_level.graduate_open,
            },

            {
              value: Constants.dog_class_element_level.utility_a,
              id: Constants.dog_class_element_level.utility_a,
            },
            {
              value: Constants.dog_class_element_level.utility_b,
              id: Constants.dog_class_element_level.utility_b,
            },
            {
              value: Constants.dog_class_element_level.preferred_utility,
              id: Constants.dog_class_element_level.preferred_utility,
            },

            {
              value: Constants.dog_class_element_level.versatility,
              id: Constants.dog_class_element_level.versatility,
            },
            {
              value: Constants.dog_class_element_level.veteran,
              id: Constants.dog_class_element_level.veteran,
            },
            {
              //value: Constants.dog_class_element_level.abi_brace,
              //id: Constants.dog_class_element_level.abi_brace,
              value: Constants.dog_class_element_level.brace,
              id: Constants.dog_class_element_level.brace,
            },
            {
              value: Constants.dog_class_element_level.team,
              id: Constants.dog_class_element_level.team,
            },
            {
              value: Constants.dog_class_element_level.wc_novice,
              id: Constants.dog_class_element_level.wc_novice,
            },
            {
              value: Constants.dog_class_element_level.wc_open,
              id: Constants.dog_class_element_level.wc_open,
            },
            {
              value: Constants.dog_class_element_level.wc_utility,
              id: Constants.dog_class_element_level.wc_utility,
            },
            {
              value: Constants.dog_class_element_level.udx,
              id: Constants.dog_class_element_level.udx,
            },
          ],
        },
      ],
    },
  ];
}
