import { Constants } from "@eagerdog/constants";
import { IEventRegisterRow } from "../../../services/helper.service.js";

export namespace ABIConformation {
  export const form: IEventRegisterRow[] = [
    {
      allowDuplicates: false,
      inputs: [
        {
          type: "checkbox",
          multiSelect: true,
          label: Constants.dog_class_element.conformation,
          options: [
            {
              value: Constants.dog_class_element_level.abi_baby_puppy,
              id: Constants.dog_class_element_level.abi_baby_puppy,
            },
            {
              value: Constants.dog_class_element_level.abi_6_9_months,
              id: Constants.dog_class_element_level.abi_6_9_months,
            },
            {
              value: Constants.dog_class_element_level.abi_9_12_months,
              id: Constants.dog_class_element_level.abi_9_12_months,
            },
            {
              value: Constants.dog_class_element_level.abi_12_18_months,
              id: Constants.dog_class_element_level.abi_12_18_months,
            },
            {
              value: Constants.dog_class_element_level.abi_bred_by_exibitor,
              id: Constants.dog_class_element_level.abi_bred_by_exibitor,
            },
            {
              value: Constants.dog_class_element_level.abi_open,
              id: Constants.dog_class_element_level.abi_open,
            },
            {
              value: Constants.dog_class_element_level.abi_altered,
              id: Constants.dog_class_element_level.abi_altered,
            },
            {
              value: Constants.dog_class_element_level.abi_jr_champion,
              id: Constants.dog_class_element_level.abi_jr_champion,
            },
            {
              value: Constants.dog_class_element_level.abi_champion,
              id: Constants.dog_class_element_level.abi_champion,
            },
            {
              value: Constants.dog_class_element_level.abi_master_champion,
              id: Constants.dog_class_element_level.abi_master_champion,
            },
            {
              value: Constants.dog_class_element_level.abi_grand_champion,
              id: Constants.dog_class_element_level.abi_grand_champion,
            },
            {
              value: Constants.dog_class_element_level.abi_supreme_champion,
              id: Constants.dog_class_element_level.abi_supreme_champion,
            },
            {
              value: Constants.dog_class_element_level.abi_ultimate_champion,
              id: Constants.dog_class_element_level.abi_ultimate_champion,
            },
            {
              value: Constants.dog_class_element_level.veteran,
              id: Constants.dog_class_element_level.veteran,
            },
            {
              value: Constants.dog_class_element_level.brace,
              id: Constants.dog_class_element_level.brace,
            },
          ],
        },
      ],
    },
  ];
}
