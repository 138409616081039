import React, { useEffect, useState, useCallback } from "react";
import { Constants } from "@eagerdog/constants";
import { IScore } from "@eagerdog/interfaces";

import Dropdown, { IOption } from "src/components/Dropdown/Dropdown";
import Input from "src/components/Input/Input";
import Checkbox from "src/components/Checkbox/Checkbox";

import "./FetchResults.scss";

interface IProps {
  result: IScore,
  onChange(result: any): void
}

interface IFetchCheck {
  value: string,
  id: string,
  checked: boolean
}

const FetchResults: React.FC<IProps> = (props) => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [hasChanged, setHasChanged] = useState<boolean>(false);

  const [dropdownAnswers, setDropdownAnswers] = useState<IOption[]>([]);
  const [checkboxAnswers, setCheckboxAnswers] = useState<IFetchCheck[]>([]);

  const [other, setOther] = useState<string>();

  const changeDropdownAnswer = (value: IOption, index: number) => {
    let _answers:IOption[] = [...dropdownAnswers];

    _answers[index] = value;

    setDropdownAnswers(_answers);
  }

  const changeCheckboxAnswer = (id: string, index: number) => {
    let _answers:IFetchCheck[] = [...checkboxAnswers];

    let findCheckbox = _answers.filter((c: IFetchCheck) => { return c.id === id; });

    if (findCheckbox.length > 0) {
      _answers[_answers.indexOf(findCheckbox[0])].checked = !_answers[_answers.indexOf(findCheckbox[0])].checked;
    }

    setCheckboxAnswers(_answers);
  }

  const createAnswerDropdown = (label: string, id: string, index: number, options?: IOption[]) => {
    let _options:IOption[] = [
      { value: "Pass", id: "Pass" },
      { value: "Needs Training", id: "Needs Training" }
    ];

    return <Dropdown label={label} id={id} value={dropdownAnswers[index]} onChange={(e: any, value: IOption) => { changeDropdownAnswer(value, index); setHasChanged(true); }} options={options ? options : _options} placeholder="" />
  }

  const createAnswerCheckboxList = (label: string, id: string, index:number) => {
    return <div className="checkboxList">
      {checkboxAnswers.map((opt: any, index: number) => {
        return (<Checkbox key={index} id={opt.id} onChange={(e: any) => { changeCheckboxAnswer(opt.id, index); setHasChanged(true); }} value={opt.checked} label={opt.value} />);
      })}
    </div>;
  }

  const createTitle = (text: string) => {
    return <>
      <div className="fetchTitle">{text}</div>
    </>;
  }

  const getLevelResultForm = () => {
    if (props.result && props.result.dog_class) {
      switch (props.result.dog_class.level) {
        case Constants.dog_class_element_level.novice:
        case Constants.dog_class_element_level.intermediate:
          return <>
            {createAnswerDropdown("Dog under control at the start line", "dog_under_control_at_the_start_line", 0)}
            {createTitle("Handler Throws 3 Singles (Center)")}
            {createAnswerDropdown("Dog Goes to Ball", "dog_goes_to_ball_1", 1)}
            {createAnswerDropdown("Picks Up Ball", "picks_up_ball_1", 2)}
            {createAnswerDropdown("Returns Ball Within 2 Steps", "returns_ball_1", 3)}
            {createTitle("Handler Throws 3 Singles (Left)")}
            {createAnswerDropdown("Dog Goes to Ball", "dog_goes_to_ball_2", 4)}
            {createAnswerDropdown("Picks Up Ball", "picks_up_ball_2", 5)}
            {createAnswerDropdown("Returns Ball Within 2 Steps", "returns_ball_2", 6)}
            {createTitle("Handler Throws 3 Singles (Right)")}
            {createAnswerDropdown("Dog Goes to Ball", "dog_goes_to_ball_3", 7)}
            {createAnswerDropdown("Picks Up Ball", "picks_up_ball_3", 8)}
            {createAnswerDropdown("Returns Ball Within 2 Steps", "returns_ball_3", 9)}
            {props.result.dog_class.level === Constants.dog_class_element_level.intermediate && <>
              {createTitle("Handler Throws 3 Singles (Left or Right)")}
              {createAnswerDropdown("Dog Goes to Ball", "dog_goes_to_ball_3", 10)}
              {createAnswerDropdown("Picks Up Ball", "picks_up_ball_3", 11)}
              {createAnswerDropdown("Returns Ball Within 2 Steps", "returns_ball_3", 12)}
            </>}
            <div className="other">
              {createTitle("Does Not Qualify")}
              {createAnswerCheckboxList("Does Not Qualify", "does_not_qualify", 0)}
              {(checkboxAnswers.filter((c: IFetchCheck) => { return c.value === "Other" && c.checked === true }).length > 0) && <>
                <Input required defaultValue={other} onChange={(e: any) => { setOther(e.target.value); setHasChanged(true); }} type="text" label="Other" placeholder="Enter another reason" />
              </>}
            </div>
          </>;
        case Constants.dog_class_element_level.advanced:
          return <>
            {createAnswerDropdown("Dog under control at the start line", "dog_under_control_at_the_start_line", 0)}
            {createTitle("Handler Throws 2 Singles (Center, Single)")}
            {createAnswerDropdown("Dog Goes to Ball", "dog_goes_to_ball_1", 1)}
            {createAnswerDropdown("Picks Up Ball", "picks_up_ball_1", 2)}
            {createAnswerDropdown("Returns to Handler", "returns_ball_1", 3)}
            {createTitle("Handler Throws 2 Singles (Left or Right, Single)")}
            {createAnswerDropdown("Dog Goes to Ball", "dog_goes_to_ball_2", 4)}
            {createAnswerDropdown("Picks Up Ball", "picks_up_ball_2", 5)}
            {createAnswerDropdown("Returns to Handler", "returns_ball_2", 6)}
            {createTitle("Handler Throws 2 Doubles #1")}
            {createAnswerDropdown("Dog Goes to Ball (Ball #1)", "dog_goes_to_ball_3", 7)}
            {createAnswerDropdown("Picks Up Ball (Ball #1)", "picks_up_ball_3", 8)}
            {createAnswerDropdown("Returns to Handler (Ball #1)", "returns_ball_3", 9)}
            {createAnswerDropdown("Dog Goes to Ball (Ball #2)", "dog_goes_to_ball_4", 10)}
            {createAnswerDropdown("Picks Up Ball (Ball #2)", "picks_up_ball_4", 11)}
            {createAnswerDropdown("Returns to Handler (Ball #2)", "returns_ball_4", 12)}
            {createTitle("Handler Throws 2 Doubles #2")}
            {createAnswerDropdown("Dog Goes to Ball (Ball #1)", "dog_goes_to_ball_5", 13)}
            {createAnswerDropdown("Picks Up Ball (Ball #1)", "picks_up_ball_5", 14)}
            {createAnswerDropdown("Returns to Handler (Ball #1)", "returns_ball_5", 15)}
            {createAnswerDropdown("Dog Goes to Ball (Ball #2)", "dog_goes_to_ball_6", 16)}
            {createAnswerDropdown("Picks Up Ball (Ball #2)", "picks_up_ball_6", 17)}
            {createAnswerDropdown("Returns to Handler (Ball #2)", "returns_ball_6", 18)}
            <div className="other">
              {createTitle("Does Not Qualify")}
              {createAnswerCheckboxList("Does Not Qualify", "does_not_qualify", 0)}
              {(checkboxAnswers.filter((c: IFetchCheck) => { return c.value === "Other" && c.checked === true }).length > 0) && <>
                <Input required defaultValue={other} onChange={(e: any) => { setOther(e.target.value); setHasChanged(true); }} type="text" label="Other" placeholder="Enter another reason" />
              </>}
            </div>
          </>;
        case Constants.dog_class_element_level.retriever:
          return <>
            {createAnswerDropdown("Dog under control at the start line", "dog_under_control_at_the_start_line", 0)}
            {createTitle("Handler Throws 1 Triple (Ball 1, Center)")}
            {createAnswerDropdown("Dog Goes to Ball", "dog_goes_to_ball_1", 1)}
            {createAnswerDropdown("Picks Up Ball", "picks_up_ball_1", 2)}
            {createAnswerDropdown("Returns to Handler", "returns_ball_1", 3)}
            {createTitle("Handler Throws 1 Triple (Ball 2, Left)")}
            {createAnswerDropdown("Dog Goes to Ball", "dog_goes_to_ball_2", 4)}
            {createAnswerDropdown("Picks Up Ball", "picks_up_ball_2", 5)}
            {createAnswerDropdown("Returns to Handler", "returns_ball_2", 6)}
            {createTitle("Handler Throws 1 Triple (Ball 3, Right)")}
            {createAnswerDropdown("Dog Goes to Ball", "dog_goes_to_ball_3", 7)}
            {createAnswerDropdown("Picks Up Ball", "picks_up_ball_3", 8)}
            {createAnswerDropdown("Returns to Handler", "returns_ball_3", 9)}
            {createTitle("Handler Throws 2 Doubles (Double #1)")}
            {createAnswerDropdown("Dog Goes to Ball (Ball #1)", "dog_goes_to_ball_4", 10)}
            {createAnswerDropdown("Picks Up Ball (Ball #1)", "picks_up_ball_4", 11)}
            {createAnswerDropdown("Returns to Handler (Ball #1)", "returns_ball_4", 12)}
            {createAnswerDropdown("Dog Goes to Ball (Ball #2)", "dog_goes_to_ball_5", 13)}
            {createAnswerDropdown("Picks Up Ball (Ball #2)", "picks_up_ball_5", 14)}
            {createAnswerDropdown("Returns to Handler (Ball #2)", "returns_ball_5", 15)}
            {createTitle("Handler Throws 2 Doubles (Double #2)")}
            {createAnswerDropdown("Dog Goes to Ball (Ball #1)", "dog_goes_to_ball_6", 16)}
            {createAnswerDropdown("Picks Up Ball (Ball #1)", "picks_up_ball_6", 17)}
            {createAnswerDropdown("Returns to Handler (Ball #1)", "returns_ball_6", 18)}
            {createAnswerDropdown("Dog Goes to Ball (Ball #2)", "dog_goes_to_ball_7", 19)}
            {createAnswerDropdown("Picks Up Ball (Ball #2)", "picks_up_ball_7", 20)}
            {createAnswerDropdown("Returns to Handler (Ball #2)", "returns_ball_7", 21)}
            <div className="other">
              {createTitle("Does Not Qualify")}
              {createAnswerCheckboxList("Does Not Qualify", "does_not_qualify", 0)}
              {(checkboxAnswers.filter((c: IFetchCheck) => { return c.value === "Other" && c.checked === true }).length > 0) && <>
                <Input required defaultValue={other} onChange={(e: any) => { setOther(e.target.value); setHasChanged(true); }} type="text" label="Other" placeholder="Enter another reason" />
              </>}
            </div>
          </>;
      }
    }
  }

  const checkIfPassed = useCallback(() => {
    let needsTraining = dropdownAnswers.filter((opt: IOption) => { return (opt.value === "Needs Training"); }).length > 0;
    let doesNotQualify = checkboxAnswers.filter((opt: IFetchCheck) => { return opt.checked; }).length > 0;

    if (needsTraining || doesNotQualify) {
      return false;
    }

    return true;
  }, [checkboxAnswers, dropdownAnswers]);

  useEffect(() => {
    if (!loaded) {
      if (props.result && props.result.dog_class) {
        const getOptionValue = (val: boolean | undefined) => {
          if (val) {
            return { value: "Pass", id: "Pass" };
          } else {
            return { value: "Needs Training", id: "Needs Training" };
          }
        };

        switch (props.result.dog_class.level) {
          case Constants.dog_class_element_level.novice:
            if (props.result.fetch && 
                props.result.fetch.novice && 
                props.result.fetch.novice.single_to_center && 
                props.result.fetch.novice.single_to_left && 
                props.result.fetch.novice.single_to_right && 
                props.result.fetch.novice.does_not_qualify && 
                props.result.fetch.novice.does_not_qualify.other_description) {
              setDropdownAnswers([
                getOptionValue(props.result.fetch.novice.dog_under_control_at_the_start_line),
                getOptionValue(props.result.fetch.novice.single_to_center.dog_goes_to_ball),
                getOptionValue(props.result.fetch.novice.single_to_center.picks_up_ball),
                getOptionValue(props.result.fetch.novice.single_to_center.returns_ball),
                getOptionValue(props.result.fetch.novice.single_to_left.dog_goes_to_ball),
                getOptionValue(props.result.fetch.novice.single_to_left.picks_up_ball),
                getOptionValue(props.result.fetch.novice.single_to_left.returns_ball),
                getOptionValue(props.result.fetch.novice.single_to_right.dog_goes_to_ball),
                getOptionValue(props.result.fetch.novice.single_to_right.picks_up_ball),
                getOptionValue(props.result.fetch.novice.single_to_right.returns_ball)
              ]);

              setCheckboxAnswers([
                { value: "Runs off Course", id: "runs_off_course", checked: props.result.fetch.novice.does_not_qualify.runs_off_course ? true : false },
                { value: "Urinates/Defecates On Course", id: "urinates_defecates", checked: props.result.fetch.novice.does_not_qualify.urinates_on_course ? true : false },
                { value: "Refusal", id: "refusal", checked: props.result.fetch.novice.does_not_qualify.refusal_to_retrieve ? true : false },
                { value: "Does Not Finish in Time", id: "does_not_finish_in_time", checked: props.result.fetch.novice.does_not_qualify.does_not_finish_in_time ? true : false },
                { value: "Other", id: "other", checked: props.result.fetch.novice.does_not_qualify.other ? true : false }
              ]);


              setOther(props.result.fetch.novice.does_not_qualify.other_description || "");
            } else {
              setDropdownAnswers([
                { value: "Needs Training", id: "Needs Training" },
                { value: "Needs Training", id: "Needs Training" },
                { value: "Needs Training", id: "Needs Training" },
                { value: "Needs Training", id: "Needs Training" },
                { value: "Needs Training", id: "Needs Training" },
                { value: "Needs Training", id: "Needs Training" },
                { value: "Needs Training", id: "Needs Training" },
                { value: "Needs Training", id: "Needs Training" },
                { value: "Needs Training", id: "Needs Training" },
                { value: "Needs Training", id: "Needs Training" }
              ]);

              setCheckboxAnswers([
                { value: "Runs off Course", id: "runs_off_course", checked: false },
                { value: "Urinates/Defecates On Course", id: "urinates_defecates", checked: false },
                { value: "Refusal", id: "refusal", checked: false },
                { value: "Does Not Finish in Time", id: "does_not_finish_in_time", checked: false },
                { value: "Other", id: "other", checked: false }
              ]);
            }
        break;
        case Constants.dog_class_element_level.intermediate:
            if (props.result.fetch && 
                props.result.fetch.intermediate && 
                props.result.fetch.intermediate.single_to_center && 
                props.result.fetch.intermediate.single_to_left && 
                props.result.fetch.intermediate.single_to_right && 
                props.result.fetch.intermediate.does_not_qualify && 
                props.result.fetch.intermediate.single_to_left_or_right &&
                props.result.fetch.intermediate.does_not_qualify.other_description) {
              setDropdownAnswers([
                getOptionValue(props.result.fetch.intermediate.dog_under_control_at_the_start_line),
                getOptionValue(props.result.fetch.intermediate.single_to_center.dog_goes_to_ball),
                getOptionValue(props.result.fetch.intermediate.single_to_center.picks_up_ball),
                getOptionValue(props.result.fetch.intermediate.single_to_center.returns_ball),
                getOptionValue(props.result.fetch.intermediate.single_to_left.dog_goes_to_ball),
                getOptionValue(props.result.fetch.intermediate.single_to_left.picks_up_ball),
                getOptionValue(props.result.fetch.intermediate.single_to_left.returns_ball),
                getOptionValue(props.result.fetch.intermediate.single_to_right.dog_goes_to_ball),
                getOptionValue(props.result.fetch.intermediate.single_to_right.picks_up_ball),
                getOptionValue(props.result.fetch.intermediate.single_to_right.returns_ball),
                getOptionValue(props.result.fetch.intermediate.single_to_left_or_right.dog_goes_to_ball),
                getOptionValue(props.result.fetch.intermediate.single_to_left_or_right.picks_up_ball),
                getOptionValue(props.result.fetch.intermediate.single_to_left_or_right.returns_ball),
              ]);

              setCheckboxAnswers([
                { value: "Runs off Course", id: "runs_off_course", checked: props.result.fetch.intermediate.does_not_qualify.runs_off_course ? true : false },
                { value: "Urinates/Defecates On Course", id: "urinates_defecates", checked: props.result.fetch.intermediate.does_not_qualify.urinates_on_course ? true : false },
                { value: "Refusal", id: "refusal", checked: props.result.fetch.intermediate.does_not_qualify.refusal_to_retrieve ? true : false },
                { value: "Does Not Finish in Time", id: "does_not_finish_in_time", checked: props.result.fetch.intermediate.does_not_qualify.does_not_finish_in_time ? true : false },
                { value: "Other", id: "other", checked: props.result.fetch.intermediate.does_not_qualify.other ? true : false }
              ]);

              setOther(props.result.fetch.intermediate.does_not_qualify.other_description || "");
            } else {
              setDropdownAnswers([
                { value: "Needs Training", id: "Needs Training" },
                { value: "Needs Training", id: "Needs Training" },
                { value: "Needs Training", id: "Needs Training" },
                { value: "Needs Training", id: "Needs Training" },
                { value: "Needs Training", id: "Needs Training" },
                { value: "Needs Training", id: "Needs Training" },
                { value: "Needs Training", id: "Needs Training" },
                { value: "Needs Training", id: "Needs Training" },
                { value: "Needs Training", id: "Needs Training" },
                { value: "Needs Training", id: "Needs Training" },
                { value: "Needs Training", id: "Needs Training" },
                { value: "Needs Training", id: "Needs Training" },
                { value: "Needs Training", id: "Needs Training" }
              ]);

              setCheckboxAnswers([
                { value: "Runs off Course", id: "runs_off_course", checked: false },
                { value: "Urinates/Defecates On Course", id: "urinates_defecates", checked: false },
                { value: "Refusal", id: "refusal", checked: false },
                { value: "Does Not Finish in Time", id: "does_not_finish_in_time", checked: false },
                { value: "Other", id: "other", checked: false }
              ]);
            }
        break;
        case Constants.dog_class_element_level.advanced:
          if (props.result.fetch && 
              props.result.fetch.advanced && 
              props.result.fetch.advanced.single_to_center &&
              props.result.fetch.advanced.single_to_left_or_right &&
              props.result.fetch.advanced.double_1 &&
              props.result.fetch.advanced.double_2 &&
              props.result.fetch.advanced.does_not_qualify) {
            setDropdownAnswers([
              getOptionValue(props.result.fetch.advanced.dog_under_control_at_the_start_line),
              getOptionValue(props.result.fetch.advanced.single_to_center.dog_goes_to_ball),
              getOptionValue(props.result.fetch.advanced.single_to_center.picks_up_ball),
              getOptionValue(props.result.fetch.advanced.single_to_center.returns_ball),
              getOptionValue(props.result.fetch.advanced.single_to_left_or_right.dog_goes_to_ball),
              getOptionValue(props.result.fetch.advanced.single_to_left_or_right.picks_up_ball),
              getOptionValue(props.result.fetch.advanced.single_to_left_or_right.returns_ball),
              getOptionValue(props.result.fetch.advanced.double_1[0].dog_goes_to_ball),
              getOptionValue(props.result.fetch.advanced.double_1[0].picks_up_ball),
              getOptionValue(props.result.fetch.advanced.double_1[0].returns_ball),
              getOptionValue(props.result.fetch.advanced.double_1[1].dog_goes_to_ball),
              getOptionValue(props.result.fetch.advanced.double_1[1].picks_up_ball),
              getOptionValue(props.result.fetch.advanced.double_1[1].returns_ball),
              getOptionValue(props.result.fetch.advanced.double_2[0].dog_goes_to_ball),
              getOptionValue(props.result.fetch.advanced.double_2[0].picks_up_ball),
              getOptionValue(props.result.fetch.advanced.double_2[0].returns_ball),
              getOptionValue(props.result.fetch.advanced.double_2[1].dog_goes_to_ball),
              getOptionValue(props.result.fetch.advanced.double_2[1].picks_up_ball),
              getOptionValue(props.result.fetch.advanced.double_2[1].returns_ball)
            ]);

            setCheckboxAnswers([
              { value: "Runs off Course", id: "runs_off_course", checked: props.result.fetch.advanced.does_not_qualify.runs_off_course ? true : false },
              { value: "Urinates/Defecates On Course", id: "urinates_defecates", checked: props.result.fetch.advanced.does_not_qualify.urinates_on_course ? true : false },
              { value: "Refusal", id: "refusal", checked: props.result.fetch.advanced.does_not_qualify.refusal_to_retrieve ? true : false },
              { value: "Does Not Finish in Time", id: "does_not_finish_in_time", checked: props.result.fetch.advanced.does_not_qualify.does_not_finish_in_time ? true : false },
              { value: "Other", id: "other", checked: props.result.fetch.advanced.does_not_qualify.other ? true : false }
            ]);

            setOther(props.result.fetch.advanced.does_not_qualify.other_description || "");
          } else {
            setDropdownAnswers([
              { value: "Needs Training", id: "Needs Training" },
              { value: "Needs Training", id: "Needs Training" },
              { value: "Needs Training", id: "Needs Training" },
              { value: "Needs Training", id: "Needs Training" },
              { value: "Needs Training", id: "Needs Training" },
              { value: "Needs Training", id: "Needs Training" },
              { value: "Needs Training", id: "Needs Training" },
              { value: "Needs Training", id: "Needs Training" },
              { value: "Needs Training", id: "Needs Training" },
              { value: "Needs Training", id: "Needs Training" },
              { value: "Needs Training", id: "Needs Training" },
              { value: "Needs Training", id: "Needs Training" },
              { value: "Needs Training", id: "Needs Training" },
              { value: "Needs Training", id: "Needs Training" },
              { value: "Needs Training", id: "Needs Training" },
              { value: "Needs Training", id: "Needs Training" },
              { value: "Needs Training", id: "Needs Training" },
              { value: "Needs Training", id: "Needs Training" },
              { value: "Needs Training", id: "Needs Training" }
            ]);

            setCheckboxAnswers([
              { value: "Runs off Course", id: "runs_off_course", checked: false },
              { value: "Urinates/Defecates On Course", id: "urinates_defecates", checked: false },
              { value: "Refusal", id: "refusal", checked: false },
              { value: "Does Not Finish in Time", id: "does_not_finish_in_time", checked: false },
              { value: "Other", id: "other", checked: false }
            ]);
          }
        break;
        case Constants.dog_class_element_level.retriever:
          if (props.result.fetch && 
              props.result.fetch.retriever &&
              props.result.fetch.retriever.triple &&
              props.result.fetch.retriever.triple.length >= 3 &&
              props.result.fetch.retriever.double_1 &&
              props.result.fetch.retriever.double_2 &&
              props.result.fetch.retriever.does_not_qualify) {

            if (props.result.fetch.retriever.triple[0].ball_result && props.result.fetch.retriever.triple[1].ball_result && props.result.fetch.retriever.triple[2].ball_result) {
              setDropdownAnswers([
                getOptionValue(props.result.fetch.retriever.dog_under_control_at_the_start_line),
                getOptionValue(props.result.fetch.retriever.triple[0].ball_result.dog_goes_to_ball),
                getOptionValue(props.result.fetch.retriever.triple[0].ball_result.picks_up_ball),
                getOptionValue(props.result.fetch.retriever.triple[0].ball_result.returns_ball),
                getOptionValue(props.result.fetch.retriever.triple[1].ball_result.dog_goes_to_ball),
                getOptionValue(props.result.fetch.retriever.triple[1].ball_result.picks_up_ball),
                getOptionValue(props.result.fetch.retriever.triple[1].ball_result.returns_ball),
                getOptionValue(props.result.fetch.retriever.triple[2].ball_result.dog_goes_to_ball),
                getOptionValue(props.result.fetch.retriever.triple[2].ball_result.picks_up_ball),
                getOptionValue(props.result.fetch.retriever.triple[2].ball_result.returns_ball),
                getOptionValue(props.result.fetch.retriever.double_1[0].dog_goes_to_ball),
                getOptionValue(props.result.fetch.retriever.double_1[0].picks_up_ball),
                getOptionValue(props.result.fetch.retriever.double_1[0].returns_ball),
                getOptionValue(props.result.fetch.retriever.double_1[1].dog_goes_to_ball),
                getOptionValue(props.result.fetch.retriever.double_1[1].picks_up_ball),
                getOptionValue(props.result.fetch.retriever.double_1[1].returns_ball),
                getOptionValue(props.result.fetch.retriever.double_2[0].dog_goes_to_ball),
                getOptionValue(props.result.fetch.retriever.double_2[0].picks_up_ball),
                getOptionValue(props.result.fetch.retriever.double_2[0].returns_ball),
                getOptionValue(props.result.fetch.retriever.double_2[1].dog_goes_to_ball),
                getOptionValue(props.result.fetch.retriever.double_2[1].picks_up_ball),
                getOptionValue(props.result.fetch.retriever.double_2[1].returns_ball)
              ]);
            }

            setCheckboxAnswers([
              { value: "Runs off Course", id: "runs_off_course", checked: props.result.fetch.retriever.does_not_qualify.runs_off_course ? true : false },
              { value: "Urinates/Defecates On Course", id: "urinates_defecates", checked: props.result.fetch.retriever.does_not_qualify.urinates_on_course ? true : false },
              { value: "Refusal", id: "refusal", checked: props.result.fetch.retriever.does_not_qualify.refusal_to_retrieve ? true : false },
              { value: "Does Not Finish in Time", id: "does_not_finish_in_time", checked: props.result.fetch.retriever.does_not_qualify.does_not_finish_in_time ? true : false },
              { value: "Other", id: "other", checked: props.result.fetch.retriever.does_not_qualify.other ? true : false }
            ]);

            setOther(props.result.fetch.retriever.does_not_qualify.other_description || "");
          } else {
            setDropdownAnswers([
              { value: "Needs Training", id: "Needs Training" },
              { value: "Needs Training", id: "Needs Training" },
              { value: "Needs Training", id: "Needs Training" },
              { value: "Needs Training", id: "Needs Training" },
              { value: "Needs Training", id: "Needs Training" },
              { value: "Needs Training", id: "Needs Training" },
              { value: "Needs Training", id: "Needs Training" },
              { value: "Needs Training", id: "Needs Training" },
              { value: "Needs Training", id: "Needs Training" },
              { value: "Needs Training", id: "Needs Training" },
              { value: "Needs Training", id: "Needs Training" },
              { value: "Needs Training", id: "Needs Training" },
              { value: "Needs Training", id: "Needs Training" },
              { value: "Needs Training", id: "Needs Training" },
              { value: "Needs Training", id: "Needs Training" },
              { value: "Needs Training", id: "Needs Training" },
              { value: "Needs Training", id: "Needs Training" },
              { value: "Needs Training", id: "Needs Training" },
              { value: "Needs Training", id: "Needs Training" },
              { value: "Needs Training", id: "Needs Training" },
              { value: "Needs Training", id: "Needs Training" },
              { value: "Needs Training", id: "Needs Training" }
            ]);

            setCheckboxAnswers([
              { value: "Runs off Course", id: "runs_off_course", checked: false },
              { value: "Urinates/Defecates On Course", id: "urinates_defecates", checked: false },
              { value: "Refusal", id: "refusal", checked: false },
              { value: "Does Not Finish in Time", id: "does_not_finish_in_time", checked: false },
              { value: "Other", id: "other", checked: false }
            ]);
          }
        break;
        }
      }

      setLoaded(true);
    }
  }, [loaded, props.result]);

  useEffect(() => {
    if (hasChanged) {
      const determineDropdownValue = (val: string) => {
        if (val === "Pass") {
          return true;
        }

        return false;
      }

      let _changed: any = {
        result_type: props.result._id
      };

      if (props.result && props.result.dog_class) {
        switch (props.result.dog_class.level) {
          case Constants.dog_class_element_level.novice:
            _changed = {
              result_type: props.result._id,
              passed: checkIfPassed(),
              novice: {
                dog_under_control_at_the_start_line: determineDropdownValue(dropdownAnswers[0].value),
                single_to_center: {
                  dog_goes_to_ball: determineDropdownValue(dropdownAnswers[1].value),
                  picks_up_ball: determineDropdownValue(dropdownAnswers[2].value),
                  returns_ball: determineDropdownValue(dropdownAnswers[3].value)
                },
                single_to_left: {
                  dog_goes_to_ball: determineDropdownValue(dropdownAnswers[4].value),
                  picks_up_ball: determineDropdownValue(dropdownAnswers[5].value),
                  returns_ball: determineDropdownValue(dropdownAnswers[6].value)
                },
                single_to_right: {
                  dog_goes_to_ball: determineDropdownValue(dropdownAnswers[7].value),
                  picks_up_ball: determineDropdownValue(dropdownAnswers[8].value),
                  returns_ball: determineDropdownValue(dropdownAnswers[9].value)
                },
                does_not_qualify: {
                  runs_off_course: checkboxAnswers[0].checked,
                  urinates_on_course: checkboxAnswers[1].checked,
                  refusal_to_retrieve: checkboxAnswers[2].checked,
                  does_not_finish_in_time: checkboxAnswers[3].checked,
                  other: checkboxAnswers[4].checked,
                  other_description: other,
                }
              }
            };
          break;
          case Constants.dog_class_element_level.intermediate:
            _changed = {
              result_type: props.result._id,
              passed: checkIfPassed(),
              intermediate: {
                dog_under_control_at_the_start_line: determineDropdownValue(dropdownAnswers[0].value),
                single_to_center: {
                  dog_goes_to_ball: determineDropdownValue(dropdownAnswers[1].value),
                  picks_up_ball: determineDropdownValue(dropdownAnswers[2].value),
                  returns_ball: determineDropdownValue(dropdownAnswers[3].value)
                },
                single_to_left: {
                  dog_goes_to_ball: determineDropdownValue(dropdownAnswers[4].value),
                  picks_up_ball: determineDropdownValue(dropdownAnswers[5].value),
                  returns_ball: determineDropdownValue(dropdownAnswers[6].value)
                },
                single_to_right: {
                  dog_goes_to_ball: determineDropdownValue(dropdownAnswers[7].value),
                  picks_up_ball: determineDropdownValue(dropdownAnswers[8].value),
                  returns_ball: determineDropdownValue(dropdownAnswers[9].value)
                },
                single_to_left_or_right: {
                  dog_goes_to_ball: determineDropdownValue(dropdownAnswers[10].value),
                  picks_up_ball: determineDropdownValue(dropdownAnswers[11].value),
                  returns_ball: determineDropdownValue(dropdownAnswers[12].value)
                },
                does_not_qualify: {
                  runs_off_course: checkboxAnswers[0].checked,
                  urinates_on_course: checkboxAnswers[1].checked,
                  refusal_to_retrieve: checkboxAnswers[2].checked,
                  does_not_finish_in_time: checkboxAnswers[3].checked,
                  other: checkboxAnswers[4].checked,
                  other_description: other,
                }
              }
            };
          break;
          case Constants.dog_class_element_level.advanced:
            _changed = {
              result_type: props.result._id,
              passed: checkIfPassed(),
              advanced: {
                dog_under_control_at_the_start_line: determineDropdownValue(dropdownAnswers[0].value),
                single_to_center: {
                  dog_goes_to_ball: determineDropdownValue(dropdownAnswers[1].value),
                  picks_up_ball: determineDropdownValue(dropdownAnswers[2].value),
                  returns_ball: determineDropdownValue(dropdownAnswers[3].value)
                },
                single_to_left_or_right: {
                  dog_goes_to_ball: determineDropdownValue(dropdownAnswers[4].value),
                  picks_up_ball: determineDropdownValue(dropdownAnswers[5].value),
                  returns_ball: determineDropdownValue(dropdownAnswers[6].value)
                },
                double_1: [{
                  dog_goes_to_ball: determineDropdownValue(dropdownAnswers[7].value),
                  picks_up_ball: determineDropdownValue(dropdownAnswers[8].value),
                  returns_ball: determineDropdownValue(dropdownAnswers[9].value)
                }, {
                  dog_goes_to_ball: determineDropdownValue(dropdownAnswers[10].value),
                  picks_up_ball: determineDropdownValue(dropdownAnswers[11].value),
                  returns_ball: determineDropdownValue(dropdownAnswers[12].value)
                }],
                double_2: [{
                  dog_goes_to_ball: determineDropdownValue(dropdownAnswers[13].value),
                  picks_up_ball: determineDropdownValue(dropdownAnswers[14].value),
                  returns_ball: determineDropdownValue(dropdownAnswers[15].value)
                }, {
                  dog_goes_to_ball: determineDropdownValue(dropdownAnswers[16].value),
                  picks_up_ball: determineDropdownValue(dropdownAnswers[17].value),
                  returns_ball: determineDropdownValue(dropdownAnswers[18].value)
                }],
                does_not_qualify: {
                  runs_off_course: checkboxAnswers[0].checked,
                  urinates_on_course: checkboxAnswers[1].checked,
                  refusal_to_retrieve: checkboxAnswers[2].checked,
                  does_not_finish_in_time: checkboxAnswers[3].checked,
                  other: checkboxAnswers[4].checked,
                  other_description: other
                }
              }
            };
          break;
          case Constants.dog_class_element_level.retriever:
            _changed = {
              result_type: props.result._id,
              passed: checkIfPassed(),
              retriever: {
                dog_under_control_at_the_start_line: determineDropdownValue(dropdownAnswers[0].value),
                triple: [{
                  ball_direction: "center",
                  ball_result: {
                    dog_goes_to_ball: determineDropdownValue(dropdownAnswers[1].value),
                    picks_up_ball: determineDropdownValue(dropdownAnswers[2].value),
                    returns_ball: determineDropdownValue(dropdownAnswers[3].value)
                  }
                }, {
                  ball_direction: "left",
                  ball_result: {
                    dog_goes_to_ball: determineDropdownValue(dropdownAnswers[4].value),
                    picks_up_ball: determineDropdownValue(dropdownAnswers[5].value),
                    returns_ball: determineDropdownValue(dropdownAnswers[6].value)
                  }
                }, {
                  ball_direction: "right",
                  ball_result: {
                    dog_goes_to_ball: determineDropdownValue(dropdownAnswers[7].value),
                    picks_up_ball: determineDropdownValue(dropdownAnswers[8].value),
                    returns_ball: determineDropdownValue(dropdownAnswers[9].value)
                  }
                }],
                double_1: [{
                  dog_goes_to_ball: determineDropdownValue(dropdownAnswers[10].value),
                  picks_up_ball: determineDropdownValue(dropdownAnswers[11].value),
                  returns_ball: determineDropdownValue(dropdownAnswers[12].value)
                }, {
                  dog_goes_to_ball: determineDropdownValue(dropdownAnswers[13].value),
                  picks_up_ball: determineDropdownValue(dropdownAnswers[14].value),
                  returns_ball: determineDropdownValue(dropdownAnswers[15].value)
                }],
                double_2: [{
                  dog_goes_to_ball: determineDropdownValue(dropdownAnswers[16].value),
                  picks_up_ball: determineDropdownValue(dropdownAnswers[17].value),
                  returns_ball: determineDropdownValue(dropdownAnswers[18].value)
                }, {
                  dog_goes_to_ball: determineDropdownValue(dropdownAnswers[19].value),
                  picks_up_ball: determineDropdownValue(dropdownAnswers[20].value),
                  returns_ball: determineDropdownValue(dropdownAnswers[21].value)
                }],
                does_not_qualify: {
                  runs_off_course: checkboxAnswers[0].checked,
                  urinates_on_course: checkboxAnswers[1].checked,
                  refusal_to_retrieve: checkboxAnswers[2].checked,
                  does_not_finish_in_time: checkboxAnswers[3].checked,
                  other: checkboxAnswers[4].checked,
                  other_description: other
                },
              }
            };
          break;
        } 
      }

      props.onChange(_changed);

      setHasChanged(false);
    }
  }, [hasChanged, props, checkIfPassed, checkboxAnswers, dropdownAnswers, other]);

  return (
    <div className="FetchResults">
      {loaded && <div className="resultWrap">
        {getLevelResultForm()}
      </div>}
    </div>
  );
};

export default FetchResults;