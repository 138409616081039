import React from 'react';
import Button from '../Button/Button';
import Table from '../Table/Table';

import './Counts.scss';

interface ICountTable {
    breed: string,
    counts: string
}

interface IProps {
  onConfirm: () => void;
  onCancel: () => void;
  buttonsDisabled?: boolean;
  tableCounts: ICountTable[];
}

const scheduleHeaders = [
    { label: "Breed", id: "breed" },
    { label: "Counts", id: "counts" }
  ];

const Counts: React.FC<IProps> = (props) => {

  return (
    <div className="Counts">
      <div className="title">Counts</div>
      <p>Below are the counts for this show/trial.</p>
      <Table 
        headers={scheduleHeaders}
        data={props.tableCounts} 
        emptyMsg={
            <div className="emptyTable">
            <span>This event doesn't have any shows, yet.</span>
            </div>
        }
      />  
      <div className="button-group">
        <Button disabled={props.buttonsDisabled ? props.buttonsDisabled : false} onClick={props.onCancel}>Cancel</Button>
        <Button disabled={props.buttonsDisabled ? props.buttonsDisabled : false} onClick={props.onConfirm}>Confirm</Button>
      </div>
    </div>
  );
}

export default Counts;